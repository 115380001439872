import React, { useState } from "react";
import { Button, Grid, Container, Box, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import httpClient from '../Components/Service';
import { ToastContext } from "../Components/ToastProvider";
import { Redirect, useHistory } from "react-router-dom";

import Header from '../Components/header';



const useStyles = makeStyles((theme: any) => ({
    paper: {
        marginTop: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
    },
    main:
    {
        textAlign: "center",
        justifyContent: "center"
    },
    img:
    {
        textAlign: "center",
        position: "relative",
        paddingTop: 20
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    margin: {
        margin: theme.spacing(2),
    },
}));

const Grant = () => {
    const classes = useStyles();
    const queryParams = new URLSearchParams(window.location.search);
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory();


    const [name, setName] = useState("")
    const [data, setData] = useState({
        //@ts-ignore    
        client_id: queryParams.get("client_id"),
        redirect_uri: queryParams.get("redirect_uri"),
        state: queryParams.get("state"),
        service: queryParams.get("service")
    });

    const [imgURl, setImgURL] = useState("")


    React.useEffect(() => {
        document.title = "Smitch | Permissions "
        getDetails()
    }, []);


    const getDetails = async () => {
        try {
            const result = await httpClient("oauth/client/get", "POST", {
                "client_id": data.client_id
            })
            if (result.data && result.data != null) {
                if (result.data.display_name) {
                    setName(result.data.display_name)
                }
                setImgURL(result.data.logo_url)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const allowHandle = async () => {
        try {
            const oAuthURL = `oauth/${data.service}/auth`;
            const result = await httpClient(oAuthURL, "POST", {
                "client_id": data.client_id,
                "redirect_uri": data.redirect_uri, "scope": "optional",
                "state": data.state,
                "user_locale": "optional",
                "response_type": "code"
            })
            if (result && result != null) {
                window.location.href = `${result.data.redirect_uri}?code=${result.data.code}&state=${result.data.state}`
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Container component="main" maxWidth="xs" className={classes.main}>
                <CssBaseline />
                <Box boxShadow={0} className={classes.margin} border={1}>
                    <div>
                        <Grid className={classes.img} >
                            {/* @ts-ignore */}
                            {imgURl != null ? <img
                                src={imgURl}
                                alt="Service logo"
                                width="60"
                                height="55"
                            /> :
                                <img
                                    src="https://smitchstorestaticfiles.azureedge.net/Logo.png"
                                    alt="Service logo"
                                    width="60"
                                    height="55"
                                />
                            }
                        </Grid>
                        <br></br>
                        <h2>
                            {" "}
                            <span style={{ color: "#469F8D" }}>{name || "SMITCH"} </span><br></br> wants to access
                            your account
                        </h2>
                        <br></br>
                        <Grid>
                            <h3
                                style={{
                                    textAlign: "left",
                                    margin: "5px",
                                    paddingLeft: "15px",
                                }}
                            >
                                This will allow{" "}
                                <span style={{ color: "#469F8D" }}>{name || "SMITCH"} </span> to :
                            </h3>
                        </Grid>

                        <br></br>

                        {/* <h4>
                            <FiberManualRecordIcon color="primary" style={{ fontSize: 15, marginTop: "5" }} />  &nbsp;&nbsp;Know who you are on
                        Google  &nbsp;&nbsp;<InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </h4> */}
                        <h4>
                            <FiberManualRecordIcon color="primary" style={{ fontSize: 15, marginTop: "5" }} /> View, recieve events and information from your devices<InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </h4>
                        <h4>
                            <FiberManualRecordIcon color="primary" style={{ fontSize: 15, marginTop: "5" }} /> Control your connnected smart device and take actions on your behalf<InfoOutlinedIcon style={{ fontSize: 15 }} />
                        </h4>
                        {/* 
                        <h3 style={{
                            textAlign: "left",
                            margin: "5px",
                            paddingLeft: "15px",
                        }}>
                            {" "}
                                Make sure that you trust{" "}
                            <span style={{ color: "#469F8D" }}>{name || "SMITCH"} </span>
                        </h3> */}
                        <Grid>
                            <p className="para"> By clicking allow, you allow this app to use your information in accordance to their terms of service and privacy policies.</p>
                        </Grid>
                        <br></br>
                        <Grid container
                            direction="row"
                            justify="space-around"
                            alignItems="flex-start" >
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    size="medium"
                                    className="deny"
                                    onClick={history.goBack}>
                                    Cancel
                                 </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    className="allow"
                                    onClick={allowHandle}
                                >
                                    Allow
                         </Button>
                            </Grid>
                        </Grid>
                        <Box mt={8}>
                        </Box>
                    </div>
                </Box>
            </Container >
        </React.Fragment>
    );
};

export default Grant;
