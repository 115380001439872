import React, { useState } from "react";
import { Button, Typography, Container, Box, CssBaseline, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import httpClient from '../Components/Service';
import { ToastContext } from "../Components/ToastProvider";
import Header from '../Components/header';



import Copyright from '../Components/copyright'
const useStyles = makeStyles((theme: any) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));

const Confirmpassword = () => {
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    const queryParams = new URLSearchParams(window.location.search);

    const [user, setUser] = useState({
        pass: "",
        confirmpass: "",
        reset_password_token: queryParams.get("reset_password_token"),
    });
    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };


    React.useEffect(() => {
        document.title = "Smitch | Reset"
    }, []);


    const handleReset = async () => {
        try {
            if (user.pass != user.confirmpass) {
                showToast("password & confrim password shoud match!", "warn");
            }
            else {
                const result = await httpClient("oauth/resetpassword", "POST", {
                    "password": user.pass, "service": "sso", "reset_password_token": user.reset_password_token
                });
                if (result && result !== null) {
                    showToast(result.message, "success");

                }
            }

        } catch (error) {
            showToast("Unable to change your password!", "error")
        }
    }
    return (
        <React.Fragment>
            <Header />

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img
                        src={require("../../src/SMITCH.png").default}
                        alt="mail logo"
                        width="60"
                        height="60"
                    />
                    <br></br>
                    <br></br>
                    <Typography component="h2"
                        color="textPrimary"

                        variant="h5">
                        Change your password
                    </Typography>
                    <br></br>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        Choose password and confirm it below
                    </Typography>

                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="pass"
                            type="password"
                            label="Enter Password "
                            name="pass"
                            autoComplete="pass"
                            autoFocus
                            value={user.pass}
                            onChange={handleChange}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="confirmpass"
                            label="Confirm Password"
                            name="confirmpass"
                            autoComplete="confirmpass"
                            autoFocus
                            value={user.confirmpass}
                            onChange={handleChange}
                        />


                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleReset}
                        >
                            Change Now
                        </Button>

                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Confirmpassword