import React from "react";
import {
    Button,
    Grid,
    Container
} from "@material-ui/core";

import PaymentHeader from "../../Components/PaymentHeader";
import PaymentFooter from "../../Components/PaymentFooter";
import axios from "axios";
import {  useHistory } from "react-router-dom";
import { ToastContext } from "../../Components/ToastProvider";



import { LIVE_CAM_BASE } from '../../Components/constant';
import {SSCAM_DEEP_LINK} from '../../Components/constant'
import { useLocation } from 'react-router-dom'


import moment from "moment";




import '../../styles/footer.css';

const FreeTrialDetails = (props) => {
    const { showToast } = React.useContext(ToastContext);
    const queryParams = new URLSearchParams(window.location.search);

    const location = useLocation();
    let project =  location.pathname.split('/')[1]

    const [data, setData] = React.useState({
        payment_token: queryParams.get("payment_token"),
        free_trial_used: queryParams.get("free_trial_used")
    });

    const [showButton , setShowButton]= React.useState(true)
    const [show, setShow] = React.useState(false)

    const [exDate, setExDate] = React.useState()

    const handleFreeTials = async (e: any) => {
        try {
            e.preventDefault()
            const result = await axios({
                method: 'post',
                url: `https://api.${project}.mysmitch.com/v1/`+"fer/user/enableFreeTrial",
                data: {},
                headers: {
                    payment_token: data.payment_token,
                    encryption: false

                }
            })
            if (result.data && result.data != null) {
                 setExDate(result.data.data.expiry_date)
                 setShowButton(false)
                 setShow(true)
                 
                //@ts-ignore
                showToast(result.data.message, "success");
                // history.push("/susbcription-enabled")
            }
        }
        catch (error) {
            if(error.response){
            console.log(error)
            // Sentry.captureException(error);
            showToast(error.response.data.message, "error")
            }
        }
    }

    // console.log("props->", props)
    // const handleBack = () => {
    //     window.open("about:blank", "_self");
    //     window.close();
    // }
    return (
        <React.Fragment>
            <PaymentHeader />
            <div style={{ backgroundColor: "black" }}>

            <Container component="main" maxWidth="md" style={{ backgroundColor: "black" }}>
        
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}

                            style={{ paddingTop: "30px", height: "100vh" }}
                            
                        >{showButton?
                                <div style={{
                                        marginTop: "55px", justifyContent: "center", alignItems
                                            : "center", color:"white"
                                    }}>
                                        <div style={{textAlign:"center" ,margin:"auto" }}>
                                        <h3 style={{paddingTop:"20px" , marginTop:"10px", margin:"auto"}}>All your devices will be upgraded   <br></br>to the <span style={{color:"#FFD700"}}>Gold</span>  Plan for 30 days.</h3>
                                        <p style={{paddingTop:"10px" , marginTop:"10px" }}>Post trial, you can choose a best plan <br></br>that works for you.</p>
                                        </div>
                                       <div style={{margin:"auto" , justifyContent:"center" , textAlign:"center"}}>
                                       <Button className="goto" style={{ color: "white", width: "275px", borderRadius: "25px", letterSpacing: "4.5px" }} onClick={handleFreeTials}>Activate  TRIAL</Button>

                                       </div>
                                    </div>:null}
                                    {show ?

                            <div style={{ textAlign: "center", paddingTop: "50px", height: "100vh", backgroundColor: "black", margin: "auto" }} >

                                <div className="newText" style={{ margin: "auto" }}>
                                    <br></br>
                                    <br></br>       <br />
                                    <br></br>
                                    <br></br>
                                    <h3 className="updated">Your have enabled free trail successfully</h3>

                                    <p>Your free trials will ends on :<br></br><span style={{color:"yellow" , paddingTop:"30px"}}>{moment(exDate).format('LLL')} </span></p>
                                    <br />
                                    <br></br>
                                    <br></br>
                                    <div style={{
                                        marginTop: "40px", justifyContent: "center", textAlign: "center", alignItems
                                            : "center"
                                    }}>
                                        <Button className="goto" style={{ color: "white", width: "275px", borderRadius: "25px", letterSpacing: "4.5px" }} href={SSCAM_DEEP_LINK}>GO BACK TO APP</Button>
                                    </div>
                                </div>
                            </div>
                            :null}

                        </Grid>

                </Grid>
            </Container>
            </div>

            <PaymentFooter />
        </React.Fragment>
    );
};

export default FreeTrialDetails;
