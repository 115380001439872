import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

const Header = () => {
    const classes = useStyles();
    let history = useHistory();
    const [visible, setVisible] = useState(false)


    const handleLogout = () => {
        localStorage.clear();
        history.push("/");
        setVisible(false)

    };

    React.useEffect(() => {
        loggedIn()

    }, []);

    const loggedIn = () => {
        const result = localStorage.getItem("access_token")
        if (result != null) {
            setVisible(true)

        }
    }


    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: "white" }}>
                    <IconButton edge="start" className={classes.menuButton} aria-label="menu">
                        <img
                            src={require("../../src/SMITCH.png").default}
                            alt="smitch logo"
                            width="40"
                            height="40"
                        />
                        <Typography variant="h6" className={classes.title}>
                            &nbsp; SMITCH
                    </Typography>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>

                    </Typography>
                    {visible ? <div>
                        <IconButton onClick={handleLogout} color="inherit">
                            <ExitToAppIcon style={{ color: "white" }} />
                        </IconButton>
                    </div> : null
                    }

                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header
