import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Button, CssBaseline, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Components/header';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));






const SsPricingPage = () => {
    const classes = useStyles();

    React.useEffect(() => {
        document.title = "Smitch Secure Premium Pricing | SMITCH";
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />

            <Header />

            <Container component="main" maxWidth="xs" >
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} style={{
                        margin: "auto", justifyContent: "center", textAlign: "center"
                    }}>
                        < img src="https://d199xmsg3owom4.cloudfront.net/images/ss_pre-1.jpg"
                            alt="iftt logo" style={{ width: "100%", marginTop: "1rem" }} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "auto", justifyContent: "center", alignItems: "center" }}>
                        <img src="https://d199xmsg3owom4.cloudfront.net/images/ss_Motion.gif"
                            alt="cloud gif" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "auto", justifyContent: "center", alignItems: "center" }}>
                        <img src="https://d199xmsg3owom4.cloudfront.net/images/ss_pre-2.jpg"
                            alt="iftt logo" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Container>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} style={{ justifyContent: "center", margin: "auto", textAlign: "center" }}>
                    <a style={{ textDecoration: "none", color: "white", width: "200px", borderRadius: "40px", backgroundImage: "linear-gradient(to right, #0c97c8, #54b77e)", padding: "15px" }} href="https://deeplinks.mysmitch.com/ss" >Check this out on your app</a>
                </Grid>
            </Grid>
            <div >
                <Grid container>

                    <Grid item md={12} sm={12} xs={12}>
                        <Box mt={8}>

                            <div style={{ margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", width: "100%", height: "80px", backgroundColor: "#27282b", color: "white" }}>
                                <Typography style={{
                                    margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", fontSize: "14px"
                                }}>
                                    IoT Monks Pvt.Ltd. {new Date().getFullYear()}
                                </Typography>
                                <a href="http://www.mysmitch.com/privacy" target="_blank" style={{
                                    margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "12px", color: "#9aa7b3", textDecoration: "none"
                                }}>
                                    Privacy Policy |
                       </a>
                                <a href="http://www.mysmitch.com/terms" target="_blank" style={{
                                    margin: "auto", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "12px", color: "#9aa7b3", textDecoration: "none"
                                }}>
                                    Terms & Conditions
                       </a>
                            </div>
                        </Box>

                    </Grid>
                </Grid>

            </div>

        </React.Fragment >
    );
}
export default SsPricingPage

