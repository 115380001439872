import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Copyright } from '../pages/payments/PlanDetails';


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      height: 250
    },
    footer: {
      padding: theme.spacing(6),
    },
  }))

const FooterSection = () => {
    const classes = useStyles();
  
  
    return (
  
      <footer className={classes.footer} style={{backgroundImage:"linear-gradient(-11deg, #0b0b0b 0%, #1f1f1f 100%)"}}>
      <div  style={{ textAlign: "center" }}>
        <img src={require("../assets/Group 551.svg").default} alt="smitch logo" />
        <Copyright />
      </div>
    </footer>
   
  
    )
  }

  export default FooterSection