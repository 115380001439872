import React from 'react';
import { Button  } from '@material-ui/core';
import '../../styles/footer.css';
import {SSCAM_DEEP_LINK} from '../../Components/constant'


const GotoApp=()=> {

//    const  handleBack=()=>{
//         window.open("about:blank", "_self");
//         window.close();
//     }
    return (
        <React.Fragment>
      
            <div style={{textAlign:"center" , paddingTop:"50px" , height:"100vh", backgroundColor:"black" , margin:"auto" }} >
  
            <div className="newText" style={{margin:"auto"}}>
                <br></br>
                <br></br>
            <h3 className="updated">Your selection has been updated</h3>
             {/* <p>You have premium active on:</p>
            
                <p style={{textAlign:"left" , marginLeft:"115px"}}>Device 1</p>
                <p style={{textAlign:"left" , marginLeft:"115px"}}>Device 2 </p>
             */}
            <p>You may manage this anytime in your profile<br></br> section in the app.</p>
            <br/>
            <br></br>
            <br></br>
            <div style={{marginTop:"25px"}}>
                <Button className="goto"  style={{color:"white" ,width:"275px",borderRadius:"25px", letterSpacing:"4.5px" }} href={SSCAM_DEEP_LINK}>GO BACK TO APP</Button>
            </div>
            </div>
            </div>
            
        </React.Fragment>
    )
}

export default GotoApp
