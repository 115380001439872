import React from 'react';
import { Button, CssBaseline,Grid } from '@material-ui/core';
import { useHistory } from "react-router-dom";


import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";


import { ToastContext } from "../../Components/ToastProvider";
import '../../styles/Payment.css';
import { PlanContext } from '../../Context/PlanContext';
import {SSO_BASE , RAZOR_PAY_KEY} from '../../Components/constant';
import PaymentHeader from '../../Components/PaymentHeader';
import FooterSection from '../../Components/PaymentFooter'



import * as Sentry from "@sentry/react";



const useStyles = makeStyles({
  table: {
    minWidth: 250,
    height: 250
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});



const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);



export function CustomizedDialogs() {

}

//main component
const PaymentYearly = (props: any) => {


  const classes = useStyles();
  const { showToast } = React.useContext(ToastContext);
  const queryParams = new URLSearchParams(window.location.search);


  const value = React.useContext(PlanContext)
  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = React.useState("")
  const [pricing, setPricing] = React.useState("")
  const [discount , setDiscount]= React.useState(0.0)
  const [planId , setPlanId] = React.useState("")
  const [limitOfDeviceText ,  setLimitOfDeviceText] = React.useState("")
  const [limitOfDeviceTwo ,  setLimitOfDeviceTwo] = React.useState("")

  const [limitOfDeviceThree ,  setLimitOfDeviceThree] = React.useState("")


  let history = useHistory();
  const [data, setData] = React.useState({
    payment_token: queryParams.get("payment_token"),
  });


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }

  
  const getToken=()=>{
    //@ts-ignore
   return localStorage.getItem("payment_token")
}

  const openPayModal = (id: any) => {
    const _window = window as any
    var rzp1 = new _window.Razorpay(
      {

        "subscription_id": id,
        key: RAZOR_PAY_KEY,
        name: plan,
        image: 'https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png',
        handler: function (response: any) {
          // console.log("id", response.razorpay_Monthly_id);
          // console.log(response.razorpay_order_id)
          // console.log(response.razorpay_signature)
          // history.push("/ok")
            history.push("/ss/payment-success")
        },
        prefill: {
          name: "",
          contact: "",
          email: "",
        },
        notes: {
          address: ""
        },
        theme: {
          hide_topbar: false
        }
      }
    )
    rzp1.open();
    rzp1.on('Monthly.failed', function (response) {
      // console.log(response.error.code);
      // console.log(response.error.description);
      // console.log(response.error.source);
      // console.log(response.error.step);
      // console.log(response.error.reason);
      // console.log(response.error.metadata.order_id);
      // console.log(response.error.metadata.payment_id);
      history.push("/ss/payment-failure")
    }
    )
  }



  const getPlan = () => {
    let config_data = JSON.parse(localStorage.getItem("config_data") as string)
    let data = localStorage.getItem("seletedPlan")
    let pricing =JSON.parse(localStorage.getItem("pricing") as string)
    setPricing(pricing)
    let data2 = pricing.yearly.price
    setPlanId(pricing.yearly.plan_id)
    let subscription = JSON.parse(localStorage.getItem("subscription") as string)
    //@ts-ignore
    setPlan(data)
    //@ts-ignore
    setDiscount(pricing.yearly.discount)
    //@ts-ignore
    setPricing(data2)
    let description = localStorage.getItem("description")
    let descriptionTwo = localStorage.getItem("descriptionTwo")
   let descriptionThree= localStorage.getItem("descriptionThree")
   //@ts-ignore
       setLimitOfDeviceText(description)
    //@ts-ignore

setLimitOfDeviceTwo(descriptionTwo)
    //@ts-ignore

setLimitOfDeviceThree(descriptionThree)

    if (subscription && config_data) {
      let subscriptionName = `Premium ${subscription.type[0]}${subscription.type.substring(1).toLowerCase()}`

      config_data.map((item) => {
        //@ts-ignore
        if (subscriptionName === item.title) {
          //@ts-ignore
          if (subscription.plan_name === "monthly") {
            data = subscriptionName
            data2 = item.pricing.yearly.price
            setLimitOfDeviceText(item.description[0])
            setLimitOfDeviceTwo(item.description[1])
            setLimitOfDeviceThree(item.description[2])            //@ts-ignore
            setPlan(data)
            //@ts-ignore
            setPricing(data2)
            setDiscount(item.pricing.yearly.discount)
          }
          else {
            data = subscriptionName
            data2 = item.pricing.monthly.price
            console.log("year amount", data2)
            //@ts-ignore
            setPlan(data)
            //@ts-ignore
            setPricing(data2)
            setDiscount(item.pricing.monthly.discount)
            setLimitOfDeviceText(item.description[0])
            setLimitOfDeviceTwo(item.description[1])
            setLimitOfDeviceThree(item.description[2])          }
        }
      })
    }
  }


  React.useEffect(() => {
    document.title = "Payment Yearly | SMITCH";
    window.scrollTo(0, 0)
    getPlan()
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);



    const makePayment = async () => {
      try {
        const result = await axios.post(SSO_BASE+`subscription/add?payment_token=${getToken()}`,
          {
            plan_id: planId,
            "service": "ss",
            "plan_name": "yearly"
          },
        )
        if (result.data) {

          openPayModal(result.data.data.id)
        }
      }
      catch (error) {
        showToast(error.response.data.message, "error")
        console.log(error)
        Sentry.captureException(error);

      }
    }



    const addPlan = (name,pricing , description , descriptionTwo , descriptionThree) => {
      localStorage.setItem("pricing" , JSON.stringify(pricing))
      localStorage.setItem("seletedPlan" ,name)
      handleClose()
      let data2 = pricing.yearly.price
      setPlanId(pricing.yearly.plan_id)
      setLimitOfDeviceText(description)
      setLimitOfDeviceTwo(descriptionTwo)
      setLimitOfDeviceThree(descriptionThree)

     setPricing(data2)
        //@ts-ignore
      setPlan(name)
      //@ts-ignore
      setDiscount(pricing.yearly.discount)
  }


    return (
      <React.Fragment>
        <CssBaseline />
        <PaymentHeader />
        
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>

       
        <div style={{ backgroundColor: "#0f0d0d", color: "white", textAlign: "center", justifyContent: "center", alignItems: "baseline", paddingTop: "40px", }}>
            <div className="change" onClick={handleClickOpen}><a>Change Plan</a></div>
          </div>
           
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  >
               {/* @ts-ignore */}
          {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}>

          </DialogTitle> */}
            <DialogContent  className="modal__manage"  style={{ width: "325px", color: "white", height: "640px" , borderRadius:"17px"}}>
         
                        {open ? 
                        <div style={{marginLeft:"17rem"}}>
                    <IconButton aria-label="close"   onClick={handleClose} style={{ backgroundColor: "#22879d", color: "white" , height:"25px" , width:"25px" }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    </div>
                 : null}

              
              <p style={{ textAlign: "center" }}>Choose Your Plan</p>
              {value[0].map((plan, i) => (
                 <div>
                 {(() => {
                   if (i <= 2) {
                       return (
                         <div  className="plan__card_dialogtwo" style={{marginTop:"10px" , paddingTop:"10px" , marginBottom:"30px"}}>
                <div style={{ textAlign: "center" ,  }} key={i}>
                      <h4 className="dialog_box_name">{plan.title}</h4>
                  <h3 className="dialog_box_text">Rs {plan.pricing.yearly.price}/Year</h3>
                  <p className="dialogbox__para">{plan.description[1]}</p>
                  
                </div>
                <div style={{margin:"auto" , justifyContent:"center" , textAlign:"center" , marginTop:"-10px"}}>
                <Button className="continue" variant="contained" style={{ width: "145px", color: "white", height: "41px", borderRadius: "25px" }} onClick={()=>addPlan(plan.title,plan.pricing ,plan.description[0], plan.description[1] ,plan.description[2])} >Continue</Button>

                  </div>
                  <br></br>
             
                </div>
                     )
                    }
                })()}
                </div>
              ))}
            </DialogContent>
          </Dialog>
        </Grid>
        </Grid>
        </div>

        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
        <div className="plan" style={{ backgroundColor: "#0f0d0d", color: "white", paddingTop: "20px" }} >
          <h2 className="plan__name">{plan}</h2>
          <div style={{ textAlign: "center", paddingTop: "0px", alignItems: "baseline" }}>
            <img src={require("../../assets/mark.png").default} alt="4K logo" />
          </div>
          {/* @ts-ignore */}
          <h2 className="plan__money">Rs {pricing-discount}/Year</h2>
          <p className="plan__text">{limitOfDeviceText}</p>
          <p className="plan__text">{limitOfDeviceTwo}</p>
          <p className="plan__text">{limitOfDeviceThree}</p>

          {/* <p className="tandc">T&C apply</p> */}

        </div>
        </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>

        <div className="table" style={{ color: "white", paddingTop: "20px" }}>
          <div style={{  color: "white", paddingTop: "20px" }} >
            <h2 className="switch__text">{plan} - Yearly</h2>
          </div>
          <TableContainer style={{ color: "white", paddingTop: "20px" }}>
            <Table className={classes.table} aria-label="simple table" >
              <TableHead style={{ color: "white" }}>
                <TableRow style={{ color: "white" }}>
                  <TableCell style={{ color: "white", alignItems: "revert" }} >Plan Name</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Qty</TableCell> */}
                  <TableCell style={{ color: "white" }} align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow key={plan}>
                  <TableCell className="namecell" component="th" scope="row" style={{ color: "white" }} >
                    {plan}
                    <p>    Discount</p>
                  </TableCell>
                  {/* <TableCell style={{ color: "white" }}  >1 </TableCell> */}
                  {/* @ts-ignore */}

                  <TableCell style={{ color: "white" }} align="right"  >{pricing}<p>{discount}</p></TableCell>

                </TableRow>

                <TableRow >
                  <TableCell style={{ color: "white", borderBottom: "none" }}><p>Total</p></TableCell>
                  {/* <TableCell style={{ color: "white", borderBottom: "none" }}  >1 </TableCell> */}
                  {/* @ts-ignore */}

                  <TableCell style={{ color: "white", borderBottom: "none" }} align="right"   > Rs: {pricing-discount}</TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div   style={{margin:"auto" , justifyContent:"center" , alignItems:"center" , textAlign:"center"}}>
            <Button className="payment__button" style={{ color: "white" , width:"275px" , borderRadius:"25px"  }} onClick={makePayment}>
              Proceed to payment
         </Button>
          </div>
          <br></br>
          <div>
            <p className="auto">You will be billed automatically every <br></br> month through our secure payment<br></br> gateway. You may choose to cancel<br></br> anytime.</p>
          </div>

        </div>
        </Grid>
        </Grid>
        <div style={{ backgroundColor: "black", height: "60px", paddingTop: "6px" }} onClick={() => history.push(`/ss/payment-monthly?payment_token=${getToken()}`)}>
          <p className="rs">Pay  Monthly</p>

        </div>
      <FooterSection/>

      </React.Fragment>
    )
  }

  export default PaymentYearly
