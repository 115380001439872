import React, { createContext } from 'react';
import httpClient from '../Components/Service';
import { SSO_BASE } from '../Components/constant';
import axios from "axios";
import { ToastContext } from "../Components/ToastProvider";
import jwt_decode from "jwt-decode";

import { useLocation } from 'react-router-dom'


export const PlanContext = React.createContext({});


export const PlanProvider = (props) => {
  const location = useLocation();
  let project =  location.pathname.split('/')[1]
  const { showToast } = React.useContext(ToastContext);


  const [plansData, setPlansData] = React.useState([])
  const queryParams = new URLSearchParams(window.location.search);
  const [data, setData] = React.useState({
    payment_token: queryParams.get("payment_token"),
  });





const checkExpiry = ()=>{ 

  if(data.payment_token && data.payment_token!=undefined){
  //@ts-ignore
  let result = jwt_decode(data.payment_token);
  //@ts-ignore
  const {exp} = result
  if(new Date(exp*1000) < new Date() )
  {
    return   showToast("Please reopen this page from app again !", "warn")

  }
}
 
}

  const fetchPlanDetails = async () => {
    try {
      if(data.payment_token && data.payment_token!=undefined){
      // const result = await httpClient(`pay/ss/config?payment_token=${data.payment_token}`, "GET");
      const result =   await axios.get(SSO_BASE+`pay/${project}/config?payment_token=${data.payment_token}`)
      if(result.data.data && result.data.data.config){
      setPlansData(result.data.data.config[0].plans)
      localStorage.setItem("config_data", JSON.stringify(result.data.data.config[0].plans))
      }
      }
    }
    catch (error) {
      if(error.response){
      showToast(error.response.data.message, "error")
      }

    }
  }








  React.useEffect(() => {
    fetchPlanDetails()
    addToken()
    checkExpiry()
  }, []);

  const addToken = () => {
    //@ts-ignore
    localStorage.setItem("payment_token", data.payment_token)
  }




  return (
    <PlanContext.Provider value={[plansData, setPlansData]}>
      {props.children}
    </PlanContext.Provider>
  )
}


