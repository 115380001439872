import React from 'react';
import { Button,CssBaseline} from '@material-ui/core';
import '../../styles/fail.css';
import PaymentHeader from '../../Components/PaymentHeader';
import PaymentFooter from '../../Components/PaymentFooter';








const PaymentFailure = () => {

    React.useEffect(() => {
        document.title = "Payment Failed!";
        window.scrollTo(0, 0)

    }, []);
  


    return (
        <React.Fragment>
         <CssBaseline />
      <PaymentHeader />
            <div style={{ color: "white", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <div>
                    <div className="firstdiv">
                        <br></br>
                        <h2 className="fail_pay">Payment failure</h2>
                        <br></br>
                        <img src={require("../../assets/fail_logo.png").default} alt="smitch logo" />
                    </div>
                    <div className="failure__div">
                        <p className="fail__text">
                            Sorry, we were unable to process that.
                        </p>
                        <br></br>
                        <p className="money">If your money was deducted, it will be refunded by your bank in 3-5 working days.</p>
                        <br></br>
                      
                        <div>
                        <Button className="chat_suppport" style={{ color: "white" , width:"275px" , borderRadius:"25px" }}>CHAT SUPPORT
                        </Button>
                        <br></br>
                        <br></br>
                    </div>
                    </div>
               
                </div>
        
                </div>
                <PaymentFooter/>

        </React.Fragment>

            

    )
}

export default PaymentFailure
