import React, { useState } from "react";
import {
    Button, Typography, Grid, Container, Box, TextField,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ToastContext } from "../../Components/ToastProvider"
import httpClient from '../../Components/Service';
import { useHistory } from "react-router"

import "../../../src/App.css";


const ClientReg = () => {

    const { showToast } = React.useContext(ToastContext);
    let history = useHistory()

    const [data, setData] = useState({
        name: "",
        redirect_uri: "",
        authorized_uri: "",
        logo_url: ""
    });

    const handleChange = (event: any) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };


    const handleSubmit = async () => {
        try {
            const result = await httpClient("client", "POST", {
                "name": data.name, "redirect_uri": data.redirect_uri, "authorized_uri": data.authorized_uri, "logo_url": data.logo_url
            });
            console.log("app", result)
            if (result && result != null) {
                showToast(result.message, "success");
                history.push("/sso/client")

            }

        } catch (error) {
            showToast("Client app creation failed!", "error")
        }
    }

    React.useEffect(() => {
        document.title = "Smitch | Client Registration";
    }, []);

    return (
        <React.Fragment>
            <Container component="main">
                <br></br>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        App information
          </Typography>
                    <p>
                        {" "}
            This shows in the consent screen, and helps end users know who you
            are and contact you
          </p>
                    <Grid container spacing={3} xs={12} md={6} >
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                id="appname"
                                name="name"
                                label="App name"
                                fullWidth
                                autoComplete="given-name"
                                variant="outlined"
                                value={data.name}
                                onChange={handleChange}

                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField

                                id="useremail"
                                name="useremail"
                                type="email"
                                label=" User support email "
                                fullWidth
                                autoComplete="eamil"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField

                                id="applogo"
                                name="applogo"
                                fullWidth
                                autoComplete="applogo"
                                variant="outlined"
                                type="file"
                                value={data.logo_url}
                                InputProps={{
                                    endAdornment: (
                                        <CloudUploadIcon />
                                    ),
                                }}
                                helperText="Upload an image, not larger than 1 MB on the consent screen that will help users recognise your app. Allowed image formats are JPG, PNG and BMP. Logos should be square."
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                id="authorized_uri"
                                name="authorized_uri"
                                label="Auth url"
                                fullWidth
                                autoComplete="redirect_uri"
                                variant="outlined"
                                value={data.authorized_uri}
                                onChange={handleChange}

                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                required
                                id="redirect"
                                name="redirect_uri"
                                label="Redirect url"
                                fullWidth
                                autoComplete="redirect_uri"
                                variant="outlined"
                                value={data.redirect_uri}
                                onChange={handleChange}

                            />
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid >
                        <Button variant="contained" color="primary" onClick={handleSubmit}>SAVE AND CONTINUE</Button>
                        <Button variant="contained" className="cancel" onClick={history.goBack}> Cancel</Button>

                    </Grid>
                </Box>
            </Container>

        </React.Fragment>
    );
};

export default ClientReg;
