import React, { useState } from 'react';
import {
    Button,
    Grid, Link,
    makeStyles,
    Typography,
    Container
} from '@material-ui/core';
import { useHistory, } from "react-router-dom";

import httpClient from '../../Components/Service'


const useStyles = makeStyles((theme) => ({
    root: {},
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
}));

const ProfileDetails = () => {
    const classes = useStyles();
    let history = useHistory();

    const [data, setData] = useState({})
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        state: '',
        country: ''
    });

    const useDate = () => {
        const locale = 'en';
        const [today, setDate] = React.useState(new Date());
        React.useEffect(() => {
            const timer = setInterval(() => {
                setDate(new Date());
            }, 60 * 1000);
            return () => {
                clearInterval(timer);
            }
        }, []);
        const day = today.toLocaleDateString(locale, { weekday: 'long' });
        const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

        const hour = today.getHours();
        const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;

        const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
        return (
            <div>
                {/* <h3>{date}</h3>
                <h3>{time}</h3> */}
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    {/* @ts-ignore */}

                    {wish}</Typography>
            </div>

        )
    };

    React.useEffect(() => {
        document.title = "Smitch | Profile";
        fetchUser()


    }, []);
    const fetchUser = async () => {
        try {
            const result = await httpClient("user", "GET");
            setData(result.data)
            if (result && result != null) {
                console.log("user", result)
            }

        } catch (error) {
            console.log(error)
        }
    }



    return (
        <Container>
            <div className={classes.heroContent}>

                <Container maxWidth="sm">

                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        {/* @ts-ignore */}

                        {useDate()}</Typography>

                    {data && data != null ? <div>
                        <Typography component="h1" variant="h2" align="center" color="secondary" gutterBottom>
                            {/* @ts-ignore */}

                            {data.email}</Typography>

                        <br></br>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            Welcome note text! Something short and leading about the collection below—its contents, the creator, etc.
                    </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Link href="/sso/register">   <Button
                                        color="primary"
                                        variant="contained"
                                    >CEATE NEW APP</Button></Link>

                                </Grid>
                                <Grid item>
                                    <Link href="/sso/client">   <Button
                                        color="secondary"
                                        variant="contained"
                                    >GOTO YOUR APPS</Button></Link>


                                </Grid>

                            </Grid>
                        </div>
                    </div>
                        : <Link href="/">   <Button
                            color="primary"
                            variant="contained"
                        >SIGN IN TO CONTINUE</Button></Link>}
                </Container>
            </div>
        </Container>

    );
};

export default ProfileDetails;
