import React, { useState } from "react";
import { Button, Typography, Grid, Container, Box, Link, CssBaseline, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../Components/copyright';
import { useHistory } from "react-router"
import httpClient from '../Components/Service'
import { ToastContext } from "../Components/ToastProvider";
import Header from '../Components/header';




const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignUp = () => {
    const classes = useStyles();
    let history = useHistory()
    const { showToast } = React.useContext(ToastContext);


    const [user, setUser] = useState({
        email: "",
        password: "",
    });
    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    React.useEffect(() => {
        document.title = "Smitch | Sign Up"
    }, []);


    const signupHandle = async (e: any) => {
        try {
            e.preventDefault()
            const result = await httpClient("signup", "POST", { "email": user.email, "password": user.password, "service": "sw" });
            console.log(result)
            if (result && result != null) {
                //@ts-ignore
                showToast(result.message, "success");
                history.push("/")
            }
        }
        catch (error) {
            console.log(error)
            showToast("Something went wrong", "error")
        }
    }

    return (
        <React.Fragment>
            <Header />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img
                        src="static/images/SMITCH.png"
                        alt="mail logo"
                        width="60"
                        height="60"
                    />
                    <br></br>
                    <br></br>
                    <Typography component="h1" variant="h5" color="textPrimary" >
                        Create new account
                          </Typography>
                    <Typography variant="caption" display="block" color="textSecondary" gutterBottom>
                        Use your email to create new account      </Typography>
                    <br></br>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={user.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={user.password}
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={signupHandle}
                        >
                            Sign Up
                    </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="/" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default SignUp 