import React from "react";
import { Box, Button, Container, Grid, Typography, makeStyles, } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import OTPInput, { ResendOTP } from "otp-input-react";


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useHistory } from "react-router"

import Copyright from "../Components/copyright";
import Paper from "@material-ui/core/Paper";


import httpClient from '../Components/Service';
import { ToastContext } from "../Components/ToastProvider";
import { DEFAULT_CLIENT_ID } from "../Components/constant";



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
    },

    form: {
        alignItems: "center",
        paddingLeft: "30px",
        width: "90%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const MobileNumber = () => {
    const classes = useStyles();
    let history = useHistory()

    const { showToast } = React.useContext(ToastContext);

    const [mobile, setMobile] = React.useState("");
    const [countrycode, setCountrycode] = React.useState("");
    const [OTP, setOTP] = React.useState("");
    const [state, setState] = React.useState(true)
    const [active, setActive] = React.useState(true)


    const queryParams = new URLSearchParams(window.location.search);
    const [res, setRes] = React.useState({
        //@ts-ignore
        client_id: encodeURIComponent(queryParams.get("client_id")||DEFAULT_CLIENT_ID) ,
        //@ts-ignore
        redirect_uri: encodeURIComponent(queryParams.get("redirect_uri")),
        response_type: queryParams.get("response_type"),
        state: queryParams.get("state"),
        service: queryParams.get("service") || "sso"

    });




    React.useEffect(() => {
        document.title = "Smitch | Mobile Login"
    }, []);




    const onSubmit = async () => {
        try {
            if (mobile) {
                const result = await httpClient("oauth/signin/mobile", "POST", { "mobile": mobile, "country_code": countrycode, "service": res.service , "client_id":res.client_id });
                console.log(result)
                setState(false)
                if (result && result != null) {
                    //@ts-ignore
                    showToast(result.message, "success");
                }
            }
            else {
                showToast("Enter valid mobile number", "error")

            }
        }
        catch (error) {
            console.log(error)
            showToast("Something went wrong", "error")
        }
    }
    const updatePhone = (value: any, data: any) => {
        //@ts-ignore
        setMobile(value.slice(data.dialCode.length))
        setCountrycode(data.dialCode)

    }

    const renderButton = buttonProps => {
        return (
            <button {...buttonProps}>
                {buttonProps.remainingTime !== 0
                    ? `Please wait for ${buttonProps.remainingTime} to resend OTP`
                    : "Resend"}
            </button>
        );
    };
    const renderTime = () => React.Fragment


    const disableButton = (e: any) => {
        console.log("no", e.target.value.length)

        if (e.target.value.length) {
            setActive(false)
        }

    }

    const otpSubmit = async () => {

        try {
            const result = await httpClient("oauth/signin/mobile/otp", "POST", {
                "otp": OTP,
                "service": "sw",
                "mobile": mobile,
                "country_code": countrycode,
            });
            console.log(result);
            if (result && result != null) {
                //@ts-ignore
                showToast(result.message, "success");
                localStorage.setItem(
                    "access_token",
                    result.data.access_token
                );
                return history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)

            }
        } catch (error) {
            console.log(error);
            showToast("Something went wrong", "error");
        }
    };

    const ResendHandle = (e) => {
        onSubmit()
    }




    return (
        <React.Fragment>
            {state ?
                <Container component="main" maxWidth="xs">
                    <Box mt={2}>
                        <CssBaseline />
                        <Paper elevation={3} style={{ height: "730px" }}>
                            <Grid component="main">
                                <br /> <br />
                                <div style={{ justifyContent: "center", textAlign: "center" }}>
                                    <img
                                        src={require("../SMITCH.png").default}
                                        alt="smitch logo"
                                        style={{ height: "60px", width: "60px" }}
                                    />
                                    <br></br>
                                    <br />
                                    <br />

                                    <Typography variant="h5" gutterBottom>
                                        Login with Mobile Number
                         </Typography>
                                    <br></br>
                                    <br />
                                    <br />
                                    <Grid>
                                        <form className={classes.form} noValidate autoComplete="off"  >
                                            <PhoneInput
                                                country={'in'}
                                                placeholder="Enter mobile Numer"
                                                countryCodeEditable={false}
                                                onChange={(value, data) => { updatePhone(value, data) }}
                                                //@ts-ignore
                                                onKeyDown={(e) => disableButton(e)}
                                            />
                                        </form>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.button}
                                        // endIcon={
                                        //     <Icon>
                                        //         <SendIcon />
                                        //     </Icon>
                                        // }
                                        onClick={onSubmit}
                                        disabled={active}
                                    >
                                        GET OTP
                            </Button>

                                    <Box mt={16}>
                                        <Copyright />
                                    </Box>
                                </div>
                            </Grid>
                        </Paper>
                    </Box>
                </Container> :
                <Container component="main" maxWidth="xs">
                    <Box mt={2}>
                        <CssBaseline />
                        <Paper elevation={3} style={{ height: "730px" }}>
                            <Grid component="main">
                                <br /> <br />
                                <div style={{ justifyContent: "center", textAlign: "center" }}>
                                    <img
                                        src={require("../SMITCH.png").default}
                                        alt="smitch logo"
                                        style={{ height: "60px", width: "60px" }}
                                    />
                                    <br></br>
                                    <br />
                                    <br />
                                    <Typography variant="h5" gutterBottom>
                                        We have sent you a OTP!
                            </Typography>
                                    <p>Enter the confirmation code below</p>
                                    <br></br>

                                    <Grid>
                                        <OTPInput style={{ display: "inline" }}
                                            value={OTP}
                                            onChange={setOTP}
                                            autoFocus
                                            OTPLength={4}
                                            otpType="number"
                                            disabled={false}
                                            maxTime={2}
                                            className="send"
                                        />
                                    </Grid>
                                    <br />
                                    <ResendOTP style={{ diplay: "block", justifyContent: "center" }} renderButton={renderButton} renderTime={renderTime} onResendClick={(e) => ResendHandle(e)} />
                                    <br />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => { setState(true) }}
                                    >
                                        Cancel
                            </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={otpSubmit}
                                    >
                                        Confirm
                            </Button>
                                    < Box mt={16} >
                                        <Copyright />
                                    </Box>
                                </div>
                            </Grid>
                        </Paper>
                    </Box>
                </Container >
            }
        </React.Fragment>
    );
};

export default MobileNumber;
