import React from 'react';
import { Typography, Container, Link } from "@material-ui/core";

import '../styles/footer.css'


const Copyright = () => {

    return (
        <Container className="footer"
            component="main"  >
            {/* <div style={{ margin: "auto", textAlign: "center", justifyContent: "center" }}>
                <img src={require("../assets/Group 551.svg")} alt="4K logo" />
            </div> */}



            <Typography variant="body2" className="foottext" align="center">

                {'Copyright © '}
                <Link color="inherit" href="http://www.mysmitch.com/" target="_blank">
                    IoT Monks Pvt. Ltd.
      </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>
    );
}

export default Copyright 