export const API_URL =
  process.env.REACT_APP_API_URL || "https://temp.api.sso.mysmitch.com/v1/"



  //"https://test-sso-app-server.prod-smitch-care.mysmitch.com/v1/";

//https://test-sso-app-server.dev-smitch-care.mysmitch.com/v1/

export const SSO_BASE =
  process.env.REACT_APP_SSO_BASE ||"https://temp.api.sso.mysmitch.com/v1/";

export const SSCAM_DEEP_LINK = (process.env.REACT_APP_SSCAM_DEEP_LINK =
  "");

export const LIVE_CAM_BASE =
  process.env.REACT_APP_LIVE_CAM_BASE || "https://temp.api.sso.mysmitch.com/v1/";
// export const SW_BASE =
//   process.env.REACT_APP_SW_BASE || "https://api.sw.mysmitch.com/v1/";

export const GCLIENT_ID =
  process.env.REACT_APP_GCLIENT_ID ||
  "533990208750-b1or8ojv1vgabc9g09kums9iutpsdvl1.apps.googleusercontent.com";

export const FACEBOOK_APP_ID =
  process.env.REACT_APP_FACEBOOK_APP_ID || "689353124980467";

export const APPLE_C_ID =
  process.env.REACT_APP_APPLE_C_ID || "com.mysmitch.sso"
//"com.mysmitch.web";

export const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || "https://temp.api.sso.mysmitch.com/v1/oauth/sso/a/authcode"
//"https://staging-app-server.dev-smitch-wearables.mysmitch.com/integrations/authorize"
//  "https://app-server.prod-smitch-global.mysmitch.com/v1/oauth/sw/a/authcode/";


export const DEFAULT_CLIENT_ID =
  process.env.REACT_APP_DEFAULT_CLIENT_ID ||
  "6e8fd70a-78c2-44f4-a85f-014d53de9cb8";

export const RAZOR_PAY_KEY =
  process.env.REACT_APP_RAZOR_PAY_KEY || "test_dummy";
//"rzp_test_NjNAZaOaN7fpZJ"
// live key "rzp_live_NCDL37G6m4FZNN"
