import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const AppDomain = () => {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                App domain
      </Typography>
            <p>To protect you and your users, Google only allows apps using OAuth to use Authorised Domains. The following information will be shown to your users on the consent screen.</p>

            <Grid container spacing={3} xs={12} md={12} >

                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="appname"
                        name="app"
                        label="Application home page"
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField

                        id="useremail"
                        name="useremail"
                        label=" Application privacy policy link "
                        fullWidth
                        autoComplete="eamil"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        id="authorized_uri"
                        name="authorized_uri"
                        label=" Application Terms of Service  link "
                        fullWidth
                        autoComplete="redirect_uri"
                        variant="outlined"


                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default AppDomain;
