import React, { useState } from "react";
import { Button, Typography, Grid, Container, Box, Link, CssBaseline, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import httpClient from '../Components/Service';
import { ToastContext } from "../Components/ToastProvider";
import Header from '../Components/header';

import axios from "axios";


import Copyright from '../Components/copyright'
import { API_URL } from "../Components/constant";
const useStyles = makeStyles((theme: any) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

}));

const Forgot = () => {
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    const queryParams = new URLSearchParams(window.location.search);

    const [user, setUser] = useState({
        email: "",
        reset_password_url: "",
        service: queryParams.get("service") || "sso"

    });
    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };


    React.useEffect(() => {
        document.title = "Smitch | Reset"
    }, []);


    const handleReset = async () => {
        try {
            const result = await axios.post(API_URL + "oauth/sendemail/passwordreset", {
                "email": user.email.toLowerCase(), "link_type": "password_reset_token",
                "reset_password_url": "https://web.mysmitch.com/sso/change",
                "service": user.service
            });
            if (result && result != null) {
                showToast(result.data.message, "success");
            }
        } catch (error) {
            showToast(error.response.data.message, "error")
        }
    }
    return (
        <React.Fragment>
            <Header />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img
                        src={require("../../src/SMITCH.png").default}
                        alt="mail logo"
                        width="60"
                        height="60"
                    />
                    <br></br>
                    <br></br>
                    <Typography component="h2" variant="h5" color="textPrimary">
                        Change your password
                    </Typography>
                    <br></br>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        Enter registered email address we will sent in instruction there.
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={user.email}
                            onChange={handleChange}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                            <Link href="/" variant="body2">
                                Login here
                        </Link>
                        </Grid> */}
                            <Grid item>
                                <Link href="/signin" variant="body2">
                                    {"Remembered Password? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>
        </React.Fragment>
    );
}

export default Forgot