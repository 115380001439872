import React from 'react';
import { Button, Grid, CssBaseline, Container } from '@material-ui/core';
import { Link as RouterLink, useHistory } from "react-router-dom";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/PaymentSuccess.css';


import httpClient from "../../Components/Service";
import { ToastContext } from "../../Components/ToastProvider";
import axios from "axios";
import { API_URL } from '../../Components/constant';
import Chip from '@material-ui/core/Chip';
import StarsIcon from '@material-ui/icons/Stars';

import moment from "moment";
import * as Sentry from "@sentry/react";
import { SSO_BASE } from '../../Components/constant';
import PaymentHeader from '../../Components/PaymentHeader';
import PaymentFooter from '../../Components/PaymentFooter';
import CircularProgress from '@material-ui/core/CircularProgress';










const PaymentSuccess = () => {
    let history = useHistory();
    const { showToast } = React.useContext(ToastContext);

    const queryParams = new URLSearchParams(window.location.search);

    const [devicelist, setDevicelist] = React.useState([])
    const [deviceArray, setDeviceArry] = React.useState([])
    const [deviceIds, setDeviceIds] = React.useState([])
    const [subscription, setSubcription] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [data, setData] = React.useState({
        payment_token: queryParams.get("payment_token")

    });

    let selectedDevices = []




    React.useEffect(() => {
        fetchDeviceDetails()
        getToken()
        document.title = "Device Selection | SMITCH";
        window.scrollTo(0, 0)
        setTimeout(async () => {
            setLoading(true)
            await checkSubscriptionFound()
        }, 10000)

    }, []);







    const getToken = () => {
        //@ts-ignore
        return localStorage.getItem("payment_token")
    }

    const [checked, setChecked] = React.useState([]);


    const handleChange = (event: any) => {
        setChecked(event.target.checked);

    }




    const handleToggle = (data: any) => {


        const newChecked = [...selectedDevices]
        console.log("value", data)
        //@ts-ignore
        const currentIndex = newChecked.indexOf(data)


        if (currentIndex === -1) {
            //@ts-ignore
            newChecked.push(data)
            //@ts-ignore
            selectedDevices = newChecked

            console.log("selectedDevices", selectedDevices)
            // console.log("new selectedDevices  2" , selectedDevices)

        }
        else {
            newChecked.splice(currentIndex, 1);
            //@ts-ignore
            selectedDevices = newChecked
        }
    }



    const checkSubscriptionFound = async () => {

        try {
            let isFound = false;
            let counter = 0
            // Razorpay is taking longer than 10 seconds to send webhook. We have fixed it by calling the backend API every second to check if we have received the payment confirmation for 300 seconds
            while (!isFound && counter < 300) {
                counter = counter + 1
                await new Promise(resolve => setTimeout(resolve, 1000))
                const result = await axios.post(API_URL + "subscription/list",
                    {
                        "payment_token": getToken(),
                        "service": "ss"
                    })
                if (result.data && result.data.data.length > 0) {
                    setSubcription(result.data.data[0])
                    setLoading(false)
                    isFound = true
                }
            }
            setLoading(false)
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    }




    const fetchSubscriptionDetails = async () => {
        try {
            const result = await axios.post(API_URL + "subscription/list",
                {
                    "payment_token": getToken(),
                    "service": "ss"
                })
            if (result.data && result.data.data.length > 0) {
                setSubcription(result.data.data[0])
                setLoading(false)

            }
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    }


    const fetchDeviceDetails = async () => {
        try {

            const result = await httpClient(`pay/ss/payments?payment_token=${getToken()}`, "GET")
            if (result && result != null) {
                setDeviceArry(result.data.devices)
            }
        }
        catch (error) {
            console.log(error)
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }

    }

    const submitSelection = async () => {
        try {
            if (selectedDevices.length == 0) {
                return showToast("Select a device first!", "warn");

            }
            //@ts-ignore
            if (selectedDevices.length > subscription.allowed_devices_count) {
                return showToast("You can't  add device more than allowed device based on plan", "warn")
            }

            const result = await axios.post(SSO_BASE + "subscription/updateDevice", {
                "payment_token": getToken(),
                //@ts-ignore
                "subscription_id": subscription.id,
                "device_ids": selectedDevices,
                "service": "ss",
                "is_add": true
            })
            //@ts-ignore
            if (result.data.status === "success") {
                showToast(result.data.message, "success")

                history.push("/ss/subscription-updated")
            }
        }
        catch (error) {
            console.log(error)
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);


        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <PaymentHeader />


            <Container component="main" style={{ backgroundColor: "black", height: "168vh" }}>
                <Grid container style={{ backgroundColor: "black" }} >
                    {loading ? <div style={{ display: 'flex', textAlign: "center", alignItems: "center", margin: "auto", justifyContent: 'center', paddingTop: "5rem" }}> <div> <CircularProgress /> <p style={{ color: "white" }}>Your payment is being processed. Sit back and relax while we confirm your payment.</p></div> </div> :

                        <Grid item sm={12} xs={12} md={12}>
                            <div style={{ backgroundColor: "black", color: "white", paddingTop: "20px" }}>
                                <h2 className="success" >Congratulations! </h2>
                                <div className="mainSection" style={{ backgroundColor: "black" }}>
                                    <div className="premium" style={{ textAlign: "center" }}>
                                        <br></br>
                                        <img src={require("../../assets/Premium_icon.png").default} alt="premium logo" />
                                        <h2 className="prem_text">You’re Premium</h2>
                                        <div style={{ textAlign: "center" }}>
                                            {/* @ts-ignore */}
                                            {subscription.type === "BRONZE" &&

                                                <Chip
                                                    icon={<StarsIcon />}
                                                    //@ts-ignore
                                                    label={subscription.type}
                                                    style={{ backgroundColor: "#CD7F32", color: "white" }}
                                                />

                                            }

                                            {/* @ts-ignore */}
                                            {subscription.type === "SILVER" &&

                                                <Chip
                                                    icon={<StarsIcon />}
                                                    //@ts-ignore
                                                    label={subscription.type}
                                                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                                                />

                                            }

                                            {/* @ts-ignore */}
                                            {subscription.type === "GOLD" &&

                                                <Chip
                                                    icon={<StarsIcon />}
                                                    //@ts-ignore
                                                    label={subscription.type}
                                                    style={{ backgroundColor: "#FFD700", color: "black" }}
                                                />

                                            }

                                            {/* @ts-ignore */}
                                            {subscription.type === "GOLD_TRIAL" &&

                                                <Chip
                                                    icon={<StarsIcon />}
                                                    //@ts-ignore
                                                    label={subscription.type}
                                                    style={{ backgroundColor: "#FFD700", color: "black" }}
                                                />

                                            }


                                        </div>
                                        <p className="premium_text">Enjoy premium on upto <br></br>
                                            {/* @ts-ignore */}
                                            {subscription.allowed_devices_count} Smitch devices</p>
                                        <br></br>
                                        {/* @ts-ignore */}
                                        <p className="pre_texttwo">Subscription will auto-renew on:<br></br> {moment(subscription.current_end).format('LLL')}</p>

                                    </div>

                                </div>
                                <div>
                                    <h2 className="choose">Choose Your Devices</h2>
                                    <p className="choose_text">You may change your selection<br></br> anytime during your subscription</p>
                                </div>
                                {deviceArray.map((device, i) =>
                                    <Grid xs={12} md={12} sm={12}>
                                        <div className="device__List__success" style={{ display: "flex", backgroundColor: "black" }} key={i}>
                                            <div style={{ paddingLeft: "30px", paddingTop: "20px", marginTop: "10px" }}>
                                                <img
                                                    src="https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png"
                                                    alt="sscam logo"
                                                    width="55"
                                                    height="55"
                                                    style={{ borderRadius: "20px" }}
                                                />
                                            </div>
                                            <br></br>
                                            {/* @ts-ignore */}
                                            {/* <p>{device.subscription.type}</p> */}
                                            {/* TODO IF user have activated device */}
                                            {/* @ts-ignore */}
                                            <h3 className="device_name">{device.name}</h3>
                                            <Grid className="icon_pos" container
                                                direction="row"
                                                justify="flex-end"
                                                alignItems="center">
                                                <FormControlLabel style={{ color: "#1f92e3" }}
                                                    //@ts-ignore
                                                    onChange={() => handleToggle(device.device_id)}
                                                    //@ts-ignore 
                                                    // value={device.device_id}
                                                    control={<Checkbox icon={<RadioButtonUncheckedRoundedIcon />}
                                                        //@ts-ignore 

                                                        style={{ color: "#1f92e3" }} checkedIcon={<CheckCircleRoundedIcon />} name={device.device_id} />}
                                                    label=""
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                )}

                                <div  >
                                    {/* @ts-ignore */}

                                    <h2 className="youmay" >You may select upto {subscription.allowed_devices_count} devices</h2>


                                    <Grid item xs={12} md={12} style={{ backgroundColor: "black", justifyContent: "center", textAlign: "center" }} >
                                        <Button className="con_selection" style={{ color: "white", letterSpacing: "4.5px", width: "275px", borderRadius: "25px" }} onClick={submitSelection}>CONFIRM SELECTION
                                        </Button>
                                        <br></br>
                                    </Grid>

                                </div>
                                <br></br>

                            </div>
                        </Grid>}

                </Grid>



            </Container>

            <div style={{ paddingTop: "130px", backgroundColor: "black", color: "white" }}>
                <PaymentFooter />
            </div>





        </React.Fragment>

    )
}

export default PaymentSuccess;
