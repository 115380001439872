import React from 'react';
import { Button,Typography, Container, CssBaseline, Grid, TextField } from '@material-ui/core';
import * as Sentry from "@sentry/react";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Checkbox from '@material-ui/core/Checkbox';
import '../../styles/ManageSubscription.css';
import httpClient from '../../Components/Service';
import { ToastContext } from "../../Components/ToastProvider";
import FooterSection from '../../Components/PaymentFooter';
import { useHistory } from "react-router";
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import StarsIcon from '@material-ui/icons/Stars';
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { SSO_BASE } from '../../Components/constant';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import moment from "moment";
import { API_URL } from "../../Components/constant";
import { PlanContext } from '../../Context/PlanContext';
import PaymentHeader from '../../Components/PaymentHeader';
import LoadingComponent from '../../Components/LoadingComponent';
import InputLabel from '@material-ui/core/InputLabel';


import {SSCAM_DEEP_LINK} from '../../Components/constant'
import Coupon from  './Coupon'





const useStyles = makeStyles(theme => ({
    input: {
        color: "red"

    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

}));



const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);





const ManageSubscription = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const value = React.useContext(PlanContext)
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    let history = useHistory()
    const [Plans, setPlans] = React.useState([])
    const [subscription, setSubscription] = React.useState({})
    const [state, setState] = React.useState([])
    const [pricing, setPricing] = React.useState(0)
    const [discount, setDiscount] = React.useState(0)
    const [open, setOpen] = React.useState(false);
    //@ts-ignore
    const [plansData , setPlansData]:Array<any>  = React.useContext(PlanContext)
    const [deviceArray, setDeviceArry] = React.useState([])
    const [deviceIds, setDeviceIds] = React.useState([])
    const [name, setName] = React.useState("")
    const [selectedPrice, setSelectedPrice] = React.useState("")
    const [data, setData] = React.useState({
        payment_token: queryParams.get("payment_token"),
        free_trial_used:queryParams.get("free_trial_used")

    });
    const [deviceHasSubscription, setDeviceHasSubscription]: Array<any> = React.useState([])
    const [deviceNoSubscription, setDeviceNoSubscription]: Array<any> = React.useState([])
    const [showCheckBox, setShowCheckBox] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [checked, setChecked] = React.useState(true);
    const [deviceSelected, setDeviceSelected] = React.useState('')
    const [subscriptionType, setSubscriptionType] = React.useState('')
    const [period, setPeriod] = React.useState("")
    const [planId ,setPlanId ]= React.useState("")
    const [confirm, setConfirm] = React.useState(false);
    

    const [openCoupon, setOpenCoupon] = React.useState(false);
    const [code, setCode] = React.useState("")
    const [copy, setCopy] = React.useState(false)
    const [applied, setApplied] = React.useState(false)
    const [loadingSpinner, setLoadingSpinner] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [fail, setFail] = React.useState(false)
    const [coupon, setCoupon] = React.useState("");
    const [validity , setValidity]=React.useState("")




    const [alert, setAlert] = React.useState(false);
    const [, setConfrimyearly] = React.useState(false)
    const handleClickOpenAlert = (id: string) => {
        setAlert(true);
        setDeviceSelected(id)
    };

    const handleCloseAlert = () => {
        setAlert(false);
    }

    const [barLoading , setBarLoading] = React.useState(false)
    const [Showbar , setShowbar]= React.useState(false)
    const [myplan ,setMyPlan]= React.useState([])



    const handleChange = (event: any) => {
        setCoupon( event.target.value)
    };

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });



    let selectedDevices =[]
    let backButton = 0

    React.useEffect(() => {

        document.title = "Manage Subscription | SMITCH";
        fetchSubscriptionDetails()
        addToken()
        fetchDeviceDetails()
        getPlan()    
        fetchPlanDetails()
        getCoupon()
          
    }, []);

    




    const fetchPlanDetails = async () => {
        try {
            // const result = await httpClient(`pay/ss/config?payment_token=${data.payment_token}`, "GET");
            const result =   await axios.get(SSO_BASE+`pay/ss/config?payment_token=${data.payment_token}`)
            setState(result.data.data.config[0].plans)
            // console.log("data==>" , state)
            handleDiscount()
        }
        catch (error) {
            if(error.response){
            showToast(error.response.data.message, "error")
            }
          }
    }

    const addToken = () => {
        //@ts-ignore
        localStorage.setItem("payment_token", data.payment_token)
    }



    const handleToggle = (data: any) => {
        const newChecked = [...selectedDevices]
        //@ts-ignore
        const currentIndex = newChecked.indexOf(data)
        if (currentIndex === -1) {
            //@ts-ignore
            newChecked.push(data)
            //@ts-ignore
            selectedDevices=newChecked
        }
        else {
            newChecked.splice(currentIndex, 1);
            //@ts-ignore
            selectedDevices=newChecked
        }
    }
    


    const getToken = () => {
        //@ts-ignore
        return localStorage.getItem("payment_token")
    }

    //fetch subscription details

    const fetchSubscriptionDetails = async () => {
        try {
            const result = await axios.post(API_URL + "subscription/list",
                {
                    "payment_token": data.payment_token,
                    "service": "ss"
                })
            if (result.data.data && result.data.data.length == 0) {
                return history.push(`/ss/subscription-plans?payment_token=${getToken()}`)

            }
            result.data.data[0]["subscription_type"] = result.data.data[0].type
            setSubscription(result.data.data[0])
            localStorage.setItem("type", result.data.data[0].type)
            setSubscriptionType(result.data.data[0].type)
            getPlan(result.data.data[0])
            
            // localStorage.setItem("subscription", JSON.stringify(result.data.data[0]))
            //@ts-ignore
            if (subscription.subscription_type === "BRONZE"){
                setDiscount(237)
                setPricing(1188)
                //@ts-ignore
            } else if (subscription.subscription_type === "SILVER"){
                setDiscount(477)
                setPricing(2388)
            } else {
                setDiscount(717)
                setPricing(3588)
            }
 

            // if (result && result.data != null) {
            //     // showToast(result.data.message, "success")
            // }
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);
        }
    }


    //fetch device details

    const fetchDeviceDetails = async () => {
        try {

            const result = await httpClient(`pay/ss/payments?payment_token=${data.payment_token}`, "GET")
            if (result.data && result.data!== null) {
                setDeviceArry(result.data.devices)
                const deviceThatHasSubscription: Array<any> = []
                const deviceWithoutSubscription: Array<any> = []
                for (let device of result.data.devices) {
                    if (device.subscription.type!=="LITE" && device.subscription.active) {
                        deviceThatHasSubscription.push(device)
                    } else {
                        deviceWithoutSubscription.push(device)
                    }
                }
                setDeviceHasSubscription(deviceThatHasSubscription)
                setDeviceNoSubscription(deviceWithoutSubscription)
            }
        }
        catch (error) {
            console.log(error)
            Sentry.captureException(error);
            showToast(error.response.data.message, "error")
        }
    }


    //remove device

    const removeFromSubscription = async (id) => {
        try {
            setBarLoading(true)
            handleCloseAlert()
            const result = await axios.post(SSO_BASE + "subscription/updateDevice", {
                "payment_token": getToken(),
                //@ts-ignore
                "subscription_id": subscription.id,
                "device_ids": [deviceSelected],
                "service": "ss",
                "is_add": false
            })
            if (result.data.data && result.data.status === "success") {
               await fetchSubscriptionDetails()
               await fetchDeviceDetails() 
               setBarLoading(false)    
                showToast(result.data.message, "success")
                }
        }
        catch (error) {
            console.log(error)
            Sentry.captureException(error);
            showToast(error.response.data.message, "error")
        }
    }

    const submitSelection = async () => {
        try {
            if (selectedDevices.length === 0) {
                return showToast("Select a device first!", "warn");

            }
            //@ts-ignore
            if (selectedDevices.length > subscription.allowed_devices_count) {
                return showToast("You can add device only No of allowed based on plan", "warn")
            }
            setShowbar(true)
            const result = await axios.post(SSO_BASE + "subscription/updateDevice", {
                "payment_token": data.payment_token,
                //@ts-ignore
                "subscription_id": subscription.id,
                "device_ids": selectedDevices,
                "service": "ss",
                "is_add": true
            })
            if (result.data && result.data.status === "success") {
                await fetchSubscriptionDetails()
                await fetchDeviceDetails()
                setShowbar(false)     
                showToast(result.data.message, "success")
         }
        }
        catch (error) {
            Sentry.captureException(error);
            showToast(error.response.data.message, "error")
        }
    }


    //subscription recom
    const getPlan = (currentSubscription=subscription) => {
        let config_data = JSON.parse(localStorage.getItem("config_data") as string)
         
        //@ts-ignore
        if ((Object.keys(currentSubscription).length > 0 )&& config_data) {
             //@ts-ignore
            setPeriod(currentSubscription.period)
             //@ts-ignore
            let subscriptionName = `Premium ${currentSubscription.type[0]}${currentSubscription.type.substring(1).toLowerCase()}`
            //@ts-ignore
            config_data.map((item) => {
                // console.log("config_data" , config_data)
                //@ts-ignore
                if (subscriptionName === item.title) {
                    setName(item.title)
                //@ts-ignore
                    if (currentSubscription.plan_name === "monthly") {
                        //@ts-ignore
                        let data2 = item.pricing.yearly.price
                        //@ts-ignore
                        setPricing(data2)
                        setDiscount(item.pricing.yearly.discount)
                        setSelectedPrice(item.pricing.monthly.price)
                        setPlanId(item.pricing.yearly.plan_id)
                    }
                    else {
                        //@ts-ignore
                        setSelectedPrice(item.pricing.yearly.price)
                        setDiscount(item.pricing.yearly.discount)

                    }
                }

            }
            )
        }
        else {
            
            let pricing = JSON.parse(localStorage.getItem("pricing") as string)
            if(pricing){
            let data2 = pricing.yearly.price
            setPricing(data2)
            setDiscount(pricing.yearly.discount)
            }

        }
    }



    const updateSubscription = async (selPricing) => {
        handleClose()
        getPlan()
        try {
            setLoading(true)

            let planId;
            if (period === "monthly") {
                //@ts-ignore
                planId = selPricing.monthly.plan_id
            }
            else {
                planId = selPricing.yearly.plan_id
            }
            const result = await axios.post(SSO_BASE + "subscription/update", {
                "payment_token": getToken(),
                //@ts-ignore
                "subscription_id": subscription.id,
                "service": "ss",
                //@ts-ignore
                "plan_id": planId
            })
            if (result.data && result.data.status == "success") {
                //@ts-ignore
                await fetchSubscriptionDetails()
                await fetchDeviceDetails()
                setLoading(false)
                showToast(result.data.message, "success")
                window.location.reload()
                

            }


        }
        catch (error) {
            Sentry.captureException(error);
            console.log(error)
            Sentry.captureException(error);
            showToast(error.response.data.message, "error")
        }
    }



    const updateToYearlySubscription = async () => {
        getPlan()

        try {
            // if (satement === false) {
            //     return showToast("Do you really want to change your plan to yearly ?", "warn")
            // }
            const result = await axios.post(SSO_BASE + "subscription/update", {
                "payment_token": getToken(),
                //@ts-ignore
                "subscription_id": subscription.id,
                "service": "ss",
                //@ts-ignore
                "plan_id":planId
            })
            if (result.data && result.data.status == "success") {
                //@ts-ignore
                showToast(result.data.message, "success")

            }
        }
        catch (error) {
            console.log(error)
            Sentry.captureException(error);
            showToast(error.response.data.message, "error")
        }
    }













//  subscription ends count
    const getRemaingDaysCount = () => {
        //@ts-ignore
        const { current_end } = subscription
        const oneDay = 24 * 60 * 60 * 1000;
        var subscriptionEndDate = new Date(current_end)
        const currentDate = new Date();
        //@ts-ignore
        const diffDays = Math.round(Math.abs((subscriptionEndDate - currentDate) / oneDay));

        return <p>Ends in {diffDays} Days</p>

    }


    const handleDiscount = () => {
        let plansArray = [...state]
                     //@ts-ignore
                let newplansArray=  plansArray.find(element=>element.title===`Premium ${subscription.type[0]}${subscription.type.substring(1).toLowerCase()}`)
                //@ts-ignore
                setDiscount(element.pricing.yearly.discount)
                //@ts-ignore
        }




        const getCoupon = async () => {
            try {
    
                const result = await axios.post(SSO_BASE + "subscriptionCoupon/get", {
                    "payment_token": getToken(),
                    "service": "ss",
                })
                if (result.data.data) {
                    setCoupon(result.data.data.couponCode) 
                    setValidity(result.data.data.additional_days_of_trial)               
                }
            }
            catch (error) {
                console.log(error)
                Sentry.captureException(error);
                // showToast(error.response.data.message, "error")
            }
        }
    


        const handleClose = () => {
            setOpen(false);
        }
    

        const handleClickOpenCoupon = () => {
            setOpenCoupon(true);
        };
    
        const handleCloseCoupon = () => {
            setOpenCoupon(false)
            setLoading(false)
            setApplied(false)
            setFail(false)
        };




        const handleApplyCoupon = async () => {
            try {
                setApplied(true)
                setLoading(true)
                setFail(false)
                const result = await axios.post(SSO_BASE + "subscriptionCoupon/apply", {
                    "couponCode": coupon,
                    //@ts-ignore
                    "service": "ss",
                    "payment_token": getToken()
                })
                if (result.data && result.data.status == "success") {
                    //@ts-ignore
                    showToast(result.data.message, "success")
                    await fetchSubscriptionDetails()
                    await fetchDeviceDetails()
                    setLoading(false)
                    setOpen(false);
                    setSuccess(true)
                    handleCloseCoupon()
                }
            }
            catch (error) {
                setLoading(false)
                setApplied(false)
                setFail(true)
                showToast(error.response.data.message, "error")
            }
        }
    




    return (
        <React.Fragment>
            <CssBaseline />
            <PaymentHeader />
            <div style={{ backgroundColor: "#0f0d0d", color: "white"}}>
                <Container component="main" maxWidth="xs">
                    <Grid container>
                    <br></br>
                    {loading ? <div style={{ display: 'flex', justifyContent: 'center' , textAlign:"center" , margin:"auto" }}><LoadingComponent /> </div> :
                        <Grid item className="main_premium" style={{margin:"auto", paddingTop:"20px" , marginTop:"40px"}} >
                                <div className="premium" style={{ textAlign: "center", justifyContent:"center" }}>
                                    <br></br>
                                    <img src={require("../../assets/Premium_icon.png").default} alt="premium logo" />
                                    {/* @ts-ignore */}
                                    <h2 className="prem_text">You’re Premium! &nbsp;{subscription.has_user_requested_cancellation ? <QueryBuilderIcon /> : null}</h2>
                                    <div style={{display:"flex" , justifyContent:"center" , textAlign:"center" , alignItems:"center"}}>
                                    {/* @ts-ignore */}
                                    {subscriptionType === "BRONZE" &&
                                                <Chip
                                                size="small"
                                                    icon={<StarsIcon />}
                                                    label={subscriptionType}
                                                    style={{ backgroundColor: "#CD7F32", color: "white" }}
                                                />
                                    }
                                    {subscriptionType === "SILVER" &&     
                                                <Chip
                                                size="small"
                                                    icon={<StarsIcon />}
                                                    label={subscriptionType}
                                                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                                                />
                                    }
                                    {subscriptionType === "GOLD" &&
                                            
                                                <Chip
                                                size="small"
                                                    icon={<StarsIcon />}
                                                    label={subscriptionType}
                                                    style={{ backgroundColor: "#FFD700", color: "black" }}
                                                />
                                    }

                                    {subscriptionType === "GOLD_TRIAL" &&                                    
                                             <Chip
                                             size="small"
                                                    icon={<StarsIcon />}
                                                    label={subscriptionType}
                                                    style={{ backgroundColor: "#FFD700", color: "black" }}
                                                />
                                  
                                    }
                                        {(() => {
                                        if (subscriptionType === "GOLD_TRIAL") {
                                            return (
                                                <div style={{ margin: "3px" }}>
                                                {/* @ts-ignore */}
                                               {getRemaingDaysCount()}
                                               
        
                                            </div>
                                            )
                                        }
                                        else{
                                            return(
                                            <div style={{ margin: "3px" }}>
                                            {/* @ts-ignore */}
                                            <Chip     size="small" label={subscription.period} />
    
                                        </div>
                                            )

                                        }
                                    })()
                                    }
                                    </div>
                                    <p className="premium_text">Enjoy premium on upto <br></br>
                                        {/* @ts-ignore */}
                                        {subscription.allowed_devices_count} devices</p>
                                    {/* @ts-ignore */}
                                    {subscription.has_user_requested_cancellation?
                                        <p style={{ color: "yellow" }} >Subscription will not renew anymore</p> :null
                                      
                                    }
                                    {(() => {
                                         {/* @ts-ignore */}
                                        if (!subscription.has_user_requested_cancellation && !subscription.has_scheduled_changes && subscriptionType !== "GOLD_TRIAL" && subscriptionType !=="LITE") {
                                            return (
                                                <p className="pre_texttwo">Subscription will auto-renew on: <br></br>
                                                {/* @ts-ignore */}
                                                {moment(subscription.current_end).format('LLL')}</p>
                                            )
                                        }
                                    })()

                                    }
                                        {(() => {
                                               {/* @ts-ignore */}
                                        if (subscription.has_scheduled_changes  && subscriptionType !== "GOLD_TRIAL" && subscriptionType !=="LITE" && !subscription.has_user_requested_cancellation) {
                                            return (
                                                <p className="pre_texttwo">Subscription will be downgraded on<br></br> 
                                                {/* @ts-ignore */}
                                                {moment(subscription.change_scheduled_at).format('LLL')}</p> 
                                            )
                                        }
                                    })()
                                    }
                                    <div >
                                
                                         {/* @ts-ignore */}
                                        {subscription.type==="GOLD_TRIAL" ?
                                                       <Button className="silver__button" style={{ color: "white", letterSpacing: "4.5px", paddingLeft: "20px", width: "275px", borderRadius: "25PX" }} onClick={() =>history.push(`/ss/subscription-plans?payment_token=${getToken()}`)} >Buy Plan
                                                       </Button>
                                        :
                                        <div  style={{marginTop:"40px" , paddingTop:"20px"}}>
                                                 {/* <Checkbox
                                            style={{ color: "#1f92e3" }}
                                            value={confirm}
                                            checked={confirm}
                                            onChange={handleConfirm}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        /> */}
                                        <Button className="silver__button" style={{ color: "white", letterSpacing: "4.5px", paddingLeft: "20px",  width:"275px", borderRadius: "25PX" }} onClick={() =>  setOpen(true)} >Change Plan
                                        
                                    </Button> </div>}
                                    </div>
                                </div>                           
                        </Grid>}


{/*   TODO UN-COMMENT this  section and  and tsignore top for coupon
{subscription.type==="GOLD_TRIAL"  && <Grid item xs={12} sm= {12} md={12}>


            <div style={{ margin: "auto", justifyContent: "center" }}>
<div style={{ textAlign: "center" }}>
    <a onClick={handleClickOpenCoupon}>Avail coupon</a>
</div>

<Dialog
    open={openCoupon}
    onClose={handleCloseCoupon}
    aria-labelledby="form-dialog-title"
    style={{ backgroundImage: "linear-gradient(to top, #0b0b0b 0%, #2f2f2f 100%)", color: "white", }}
>
    <DialogContent style={{ backgroundImage: "linear-gradient(to top, #0b0b0b 0%, #2f2f2f 100%)", color: "white", borderRadius: "20px" }} >
        <DialogContentText style={{ color: "white" }}>
        <Typography variant="h6"  display="block" gutterBottom style={{ color: "white" }}>
        Redeem your Coupon
      </Typography>
      <br></br>  
            Once you confirm coupon code.Coupon will be added to your subscription and you will not be reuse it.
        <div>{ validity && validity!=undefined && <p> You will get {validity} days extra.</p>}</div> 
    </DialogContentText>
        {loading &&
            <div style={{ justifyContent: "center", margin: "auto", display: "flex" }}>
                <LoadingComponent />
            </div>
        }
        {!applied &&
            <div color="white" >
                <br></br>
                <InputLabel htmlFor="component-disabled" style={{ color: "white" }}>Enter Coupon Code    </InputLabel>
                <TextField
                    autoFocus
                    className=""
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    name="coupon"
                    value={coupon}
                    onChange={handleChange}
                    InputProps={{
                        style: {
                            color: "black"
                            , backgroundColor: "white", borderRadius: "10px", height: "40px", paddingLeft: "25px"
                        },
                        disableUnderline: true
                    }}
                />
            </div>
        }
        {success &&
            <div style={{ justifyContent: "center", margin: "auto", display: "flex" }}>
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                    >
                        <CheckIcon />
                    </Fab>
                </div>
            </div>
        }
 
        {!success?
        <div style={{ display: "flex", marginLeft: "178px" }}>
            <Button onClick={handleCloseCoupon} style={{ color: "white" }} variant="outlined" size="small" color="secondary" >
                Cancel
        </Button>

            <Button onClick={handleApplyCoupon} variant="contained" size="small" color="primary" style={{ marginLeft: "5px" }}>
                Apply
        </Button>
        </div>:null}
    </DialogContent>
</Dialog>
</div> 

                    </Grid>
                    */}

                    </Grid>
                </Container>
                <div  >
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}  >
                        {/* @ts-ignore */}
                        <DialogContent className="modal__manage" style={{ backgroundColor:"black", width: "300px", color: "white" , borderRadius:"17px" }}>
                            {subscriptionType !== "GOLD_TRIAL" ?
                                <div>
                                    
                                    <div style={{display:"flex"}}>
                                    <p style={{ textAlign: "left" }}>Current Plan</p>
                                    <div style={{textAlign:"right" , justifyContent:"flex-end" , alignItems:"center" , marginLeft:"10rem"}}>
                                    {open ? 
                                    <IconButton aria-label="close"   onClick={handleClose} style={{ color: "#22879d" , height:"25px" , width:"25px" }}>
                                    <CancelIcon />
                                    </IconButton>
                                    : null}
                                    </div>
                                    </div>
                                    <div className="plan__card_dialogOne" style={{ textAlign: "left", paddingLeft: "30px", paddingTop: "10px" }} >
                                        <h4 className="dialog_name">{name} </h4>
                                        {/* @ts-ignore */}
                                        <h3 className="dialog_text">Rs{selectedPrice}/ {subscription.period}</h3>
                                        <p  className="dialog_para">Add upto
                                        {/* @ts-ignore */}
                                        {subscription.allowed_devices_count} Smitch devices with premium</p>
                                        {/* <Button className="continue" variant="contained" style={{ width: "145px", marginLeft: "55px", letterSpacing: "4.5px", color: "white", height: "41px", borderRadius: "25px" }} onClick={handleClose}>Continue</Button> */}
                                    </div>
                                </div>
                                : null}
                            <p style={{ textAlign: "center" }}>Change Plan</p>
                            {plansData.map((plan, i) => (
                                <div key={i}>
                                    {(() => {
                                        if (plan.title !== `Premium ${subscriptionType[0]}${subscriptionType.substring(1).toLowerCase()}` && plan.title !== `Premium Gold Trial`) {
                                            return (
                                                <div style={{ marginTop: "20px" }}>
                                                    <div className="plan__card_dialog" style={{ textAlign: "left", paddingLeft: "30px", paddingTop: "10px" }} key={i}>
                                                        <h4 className="dialog_name">{plan.title}</h4>{period === "monthly" ?
                                                            <h3 className="dialog_text">Rs {plan.pricing.monthly.price}/Monthly</h3> :
                                                            <h3 className="dialog_text">Rs {plan.pricing.yearly.price}/Yearly</h3>}
                                                        <p className="dialog_para">{plan.description[0]}</p>
                                                    </div>
                                                    <div style={{ justifyContent: "center", textAlign: "center", alignItems: "center", marginTop: "-15px" }}>
                                                        <Button className="continue" variant="contained" style={{ width: "145px", color: "white", height: "41px", borderRadius: "25px", letterSpacing: "4.5px" }} onClick={() => updateSubscription(plan.pricing)}  >SELECT </Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            ))}
                        </DialogContent>
                    </Dialog>
                </div>
                <Container component="main" maxWidth="xs">
                {barLoading ? <div style={{ display: 'flex', justifyContent: 'center' , textAlign:"center" , margin:"auto" , paddingTop:"50px"  }}><LoadingComponent /> </div> :
                    <Grid container>
                        {deviceHasSubscription.length > 0 ?
                            <Grid item style={{margin:"auto"}}>
                                <br></br>
                                <h2 style={{ textAlign: "center", paddingTop: "55px" , marginTop:"20px" }}>Device with subscription</h2>
                                {deviceHasSubscription.map((device, i) =>
                                    <div className="devicelist__card" style={{ textAlign: "right", paddingTop: "10px", paddingRight: "17px", marginTop: "15px" }} key={i}>
                                        {subscriptionType === "BRONZE" &&
                                            <Chip
                                                label={device.subscription.type}
                                                size="small"
                                                style={{ backgroundColor: "#CD7F32", color: "white" }}
                                            />
                                        }
                                        {subscriptionType === "SILVER" &&
                                            <Chip
                                            size="small"
                                                label={device.subscription.type}
                                                style={{ backgroundColor: "#C0C0C0", color: "white" }}
                                            />
                                        }
                                        {subscriptionType === "GOLD" &&
                                            <Chip
                                            size="small"
                                                label={device.subscription.type}
                                                style={{ backgroundColor: "#FFD700", color: "black" }}
                                            />
                                        }
                                        {subscriptionType === "GOLD_TRIAL" &&
                                            <Chip
                                            size="small"
                                                label={device.subscription.type}
                                                style={{ backgroundColor: "#FFD700", color: "black" }}
                                            />
                                        }
                                        <div style={{ display: "flex" }}>
                                            <div style={{ paddingLeft: "30px" }}>
                                                <img
                                                    src="https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png"
                                                    alt="sscam logo"
                                                    width="55"
                                                    height="55"
                                                    style={{ borderRadius: "20px" }}
                                                />
                                            </div>
                                            {/* @ts-ignore */}
                                            <h3 className="device_name_manage">{device.name} </h3>
                                            <Grid    container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center">      
                                            
                                        {/* @ts-ignore */}
                                        {device.subscription.type === "GOLD_TRIAL" ? null:
                                                <IconButton aria-label="close" style={{ color: "#1f92e3",  }} onClick={() => handleClickOpenAlert(device.device_id)}>
                                                    <CancelIcon />
                                                </IconButton> 
                                                }
                                                <Dialog
                                                    open={alert}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent style={{ backgroundImage: "linear-gradient(to top, #0b0b0b 0%, #2f2f2f 100%)", color: "white" }}>
                                                        <DialogContentText id="alert-dialog-description" style={{ color: "white", paddingTop: "10px" }}>
                                                            Are you sure to remove device from subscription ?
                                                    </DialogContentText>
                                                        <div style={{ display: "flex", marginLeft: "178px" }}>
                                                            <Button onClick={handleCloseAlert} style={{ color: "white" }}>
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={() => removeFromSubscription(device.device_id)} autoFocus style={{ color: "white" }}>
                                                                Ok
                                                        </Button>
                                                        </div>
                                                    </DialogContent>
                                                </Dialog>
                                                </Grid>
                                        </div>
                                    </div>
                                )}
                            </Grid> : null
                        }
                    </Grid>}
                </Container>
                {deviceNoSubscription.length > 0 ?
                    <Container component="main" maxWidth="xs">
                    {Showbar ? <div style={{ display: 'flex', justifyContent: 'center' , textAlign:"center" , margin:"auto" , paddingTop:"50px" }}><LoadingComponent /> </div> :
                        <Grid container>
                            <Grid item style={{margin:"auto"}}> 
                                <br></br>
                                <h2 style={{ textAlign: "center",paddingTop:"3rem" }}>Device without subscription</h2>
                                {deviceNoSubscription.map((device, i) =>
                                    <div className="devicelist__card" style={{ textAlign: "right", paddingTop: "45px", paddingRight: "17px" }} key={i}>
                                        {/* <Chip
                                            //@ts-ignore
                                            label={device.subscription.type}
                                            color="secondary"
                                        //@ts-ignore
                                        ></Chip> */}
                                        <div style={{ display: "flex" }}>
                                            <div style={{ paddingLeft: "30px" }}>
                                                <img
                                                    src="https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png"
                                                    alt="sscam logo"
                                                    width="55"
                                                    height="55"
                                                    style={{ borderRadius: "20px" }}
                                                />
                                            </div>
                                            {/* @ts-ignore */}
                                            <h3 className="device_name_manage" style={{textAlign:"left"}}>{device.name} </h3>
                                            {device.subscription.free_trial_used && subscriptionType ==="GOLD_TRIAL"  ? <Chip     size="small" label="Trial used" />:
                                            <Grid  container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center">
                                            <FormControlLabel style={{ color: "#1f92e3", paddingLeft: "21px" }}
                                                //@ts-ignore
                                                onChange={() => handleToggle(device.device_id)}
                                                
                                                //@ts-ignore
                                                // checked={device.subscription.active}
                                                //@ts-ignore
                                                control={<Checkbox icon={<RadioButtonUncheckedRoundedIcon />}
                                                    style={{ color: "#1f92e3" }} checkedIcon={<CheckCircleRoundedIcon />} name={device.name} />}
                                                label=""
                                            />
                                            </Grid>
                                            } 
                                            {/* @ts-ignore */}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                            <div style={{ backgroundColor: "#0f0d0d", height: "200px" , margin:"auto" }}>
                           {/* { device.subscription.free_trial_used && subscriptionType ==="GOLD_TRIAL"  ? */}
                                <Grid style={{ backgroundColor: "#0f0d0d" }} >
                                    <p style={{ textAlign: "center" }}>Select device to add on subscription.</p>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <div style={{margin:"auto" , justifyContent:"center" , textAlign:"center"}}>
                                    <Button className="con_selection" style={{ color: "white", letterSpacing: "4.5px", width: "275px", borderRadius: "25px" }} onClick={submitSelection}>ADD SELECTED DEVICES
                                    </Button>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>}
                    </Container> : null}
                <Container component="main" maxWidth="xs">
                    <Grid container>
                        <Grid item xs={12} md={12} sm={12} style={{margin:"auto"}}>
                            <div style={{ backgroundColor: "#0f0d0d", color: "white", paddingTop: "20px" }} >
                                {(() => {
                                    {/* @ts-ignore */ }
                                    if (subscriptionType !== "GOLD_TRIAL" && subscription.period === "monthly") {
                                        return (
                                            <div style={{ backgroundColor: "#0f0d0d"}}>
                                                <div className="youcan" style={{ backgroundColor: "#0f0d0d", color: "white" }}>
                                                    <h1 className="save">You can save <span style={{ color: "#5da755" }} >Rs {discount}</span>
                                                        <p className="save__text">You get 20% off when you subscribe to the yearly plan</p>
                                                    </h1>
                                                </div>
                                                <div className="choose_bill_yearly">
                                                    <h4 className="yearly__name" style={{paddingTop:"40px"}}>Choose to Bill Yearly</h4>
                                                    <div style={{ textAlign: "center", paddingTop: "0px", alignItems: "baseline" }}>
                                                        <img src={require("../../assets/mark.png").default} alt="4K logo" />
                                                    </div>
                                                    {/* @ts-ignore */}
                                                    <h2 className="yearly__money_one">Rs {pricing - discount}/ Year</h2>
                                                    {/* @ts-ignore */}
                                                    <h2 className="cross_text"><del>Rs {pricing}/ Year </del></h2>
                                                    <div style={{ justifyContent: "center", textAlign: "center" , marginTop:"50px"}}>
                                                        {/* <Checkbox
                                                            style={{ color: "#1f92e3" }}
                                                            value={}
                                                            checked={}
                                                            onChange={handleChangeYearly}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        /> */}
                                                    </div>
                                                    <div className="switch__button_yearly"  style={{justifyContent:"center" , textAlign:"center" , marginLeft:"4px" }} >                                                    
                                                        <Button style={{ color: "white",width: "275px", borderRadius: "25px", letterSpacing: "4.5px" , paddingLeft:"4px" }} onClick={() => updateToYearlySubscription()} >
                                                            Switch to yearly
                                                        </Button>
                                                    </div>  
                                                </div>
                                            </div> )
                                    }
                                })()}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container component="main" maxWidth="xs" style={{ backgroundColor: "#0f0d0d", color: "white" }}>
                    <Grid container>
                        <Grid item xs={12} md={12} sm={12}>
                            <div  style={{ backgroundColor: "#0f0d0d", color: "white",  marginTop: "50px" }}>
                                {(() => {
                                    {/* @ts-ignore */ }
                                    if (!subscription.has_user_requested_cancellation && subscriptionType!="GOLD_TRIAL" && subscriptionType!="LITE" ) {
                                        return (
                                            <div style={{justifyContent:"center" , margin:"auto" , alignItems:"center"}}>
                                                <div style={{justifyContent:"center" , alignItems:"center"}}>
                                                <h1 className="need_break">Need A <br></br>Break?</h1>

                                                </div>
                                                <div style={{ paddingLeft: "58px" }}>
                                                    <img src={require("../../assets/mark.png").default} alt="mark logo" />
                                                </div>
                                                <div >
                                                    <p className="you__can">You can choose to  cancel<br></br> your subscription anytime.</p>
                                                    <br></br>
                                                </div>
                                            </div>
                                        )}
                                })()}
                                <br></br>
                                <div >
                                    {(() => {
                                        {/* @ts-ignore */ }
                                        if (!subscription.has_user_requested_cancellation  && subscriptionType!=="GOLD_TRIAL" && subscriptionType!=="LITE" ) {
                                            return (
                                                <div style={{ color: "white" , paddingTop:"30px" , paddingBottom:"20px" , margin:"auto" , justifyContent:"center" , textAlign:"center"}}>
                                                    <Button className="switch__buttonOne" style={{ color: "white", width: "275px", borderRadius: "25px", letterSpacing: "4.5px" }} onClick={() => history.push(`/ss/subscription-cancel?payment_token=${getToken()}`)}>
                                                    Cancel Subscription
                                                </Button>
                                                </div>
                                            )
                                        }
                                        //@ts-ignore
                                        else if(subscription.has_user_requested_cancellation ) {
                                            return (
                                                <div>
                                                    <p className="can_request">Your subscription cancellation<br></br> has been initiated. </p>
                                                    {/* @ts-ignore */}
                                                    <p className="can_request">{moment(subscription.current_end).format('LLL')}</p>
                                                    <br></br>
                                                </div>
                                            )
                                        }
                                    })()}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container component="main" maxWidth="xs" style={{ backgroundColor: "#0f0d0d", color: "white" }}>
                    <Grid container style={{ backgroundColor: "rgb(15, 13, 13)", color: "white",marginTop: "60px" }}  >
                        <Grid item xs={12} md={12} sm={12} className="need" style={{marginTop:"70px", margin:"auto" , paddingLeft:"40px" }} >
                        
                            <h1 className="feed__text">
                            Anything else?
                            <div>
                                <img src={require("../../assets/mark.png").default} alt="mark logo" />
                            </div>
                            </h1>
                            <div style={{marginTop:"30px"}}>
                            <p className="need_para_manage">
                            Feel free to drop us a message <br></br>and our support team will get<br></br> back to you very soon.</p>

                            </div>
                        </Grid>
                    <div style={{ color: "white" , paddingTop:"30px" , paddingBottom:"20px" , margin:"auto"}}>
                    <Button className="chat_suppport" style={{ color: "white" , paddingTop:"20px" , letterSpacing:"4.5px" , width:"275px" , borderRadius:"25px"}} href="https://chatsupport.mysmitch.com" target="_blank">CHAT SUPPORT
                        </Button>
                    </div>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </Container>
                <FooterSection />
            </div>
        </React.Fragment>
    )
}

export default ManageSubscription
