import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Card, CardContent, CardHeader, Divider, Grid, Link, makeStyles, Typography, Container } from '@material-ui/core';
import { useHistory, } from "react-router-dom";

import httpClient from '../../Components/Service'

const useStyles = makeStyles(() => ({
    root: {
        color: "#000000"
    }
}));

const ClientDetails = () => {
    const classes = useStyles();
    let history = useHistory();
    const [data, setData] = useState([])

    React.useEffect(() => {
        document.title = "Smitch | Profile";
        fetchClient()

    }, []);
    const fetchClient = async () => {
        try {
            const result = await httpClient("client", "GET");
            setData(result.data)
            if (result && result != null) {
                console.log("user", result)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const DeleteHandle = async (data: any, e: any) => {
        e.preventDefault()
        try {
            const result = await httpClient("client", "DELETE", { "client_id": data.client_id });
            setData(result.data)
            console.log("detele", result)
            if (result && result != null) {
                console.log("delete app", result)
            }
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <Container   >
            <Grid className={classes.root}>
                <form
                    autoComplete="off"
                    noValidate
                    className={clsx(classes.root)}
                >
                    <Card>
                        <Divider />
                        <Link href="/app/auth">   <Button
                            color="secondary"
                            variant="contained"
                        > ALLOW PAGE</Button></Link>
                        <Divider />
                    </Card>
                    <Grid>
                        <Card >
                            <Typography >
                                Client App Details
                            </Typography>

                            <CardContent>
                                {data && data != null ?
                                    <Grid>
                                        {data.map((item: any, i: any) => (
                                            <div>
                                                <Typography>
                                                    App Name: {item.name}

                                                </Typography>
                                                <Divider />

                                                <Typography>
                                                    Status: {item.active ? "ACTIVE" : "FALSE"}
                                                </Typography>

                                                <Typography>
                                                    Redirect URI: {item.redirect_uri}

                                                </Typography>
                                                <Typography>
                                                    Authorized URI: {item.authorized_uri}

                                                </Typography>
                                                <Typography>
                                                    Client Id: {item.client_id}

                                                </Typography>

                                                <Link href="/home">   <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={(e) => DeleteHandle(item, e)}
                                                >Delete App</Button></Link>




                                            </div>
                                        ))}
                                    </Grid>
                                    : <h3>No App details found!</h3>}


                            </CardContent>
                        </Card>
                    </Grid>


                </form>
            </Grid>
        </Container>
    );
};

export default ClientDetails;
