import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Offline } from "react-detect-offline";
import AuthenticatedRoute from "./Components/auth";
import SignIn from "./pages/SignIn";
import Forgot from "./pages/ForgotPassword";
import SignUp from "./pages/SignUp";
import Checkout from "./pages/client/Checkout";
import ClientDetails from "./pages/client/clientDetails";
import Confirmpassword from "./pages/ChangePassword";
import ProfileDetails from "./pages/profile/profileDetails";
import ClientReg from "./pages/client/ClientReg";
import Grant from "./pages/Consent";
import { ToastProvider } from "./Components/ToastProvider";
import Validate from './pages/ClientValidate'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import amber from '@material-ui/core/colors/amber';
import { PlanProvider } from './Context/PlanContext'

import PagenotFound from './pages/Pagenotfound';
import PlanDetails from './pages/payments/PlanDetails';
import MobileNumber from "./pages/MobileNumberLogin";
import PaymentFailure from './pages/payments/PaymentFailure';
import PremiumCancelled from './pages/payments/PremiumCancelled';
import PaymentSuccess from './pages/payments/PaymentSuccess';
import PaymentMonthly from './pages/payments/PaymentMonthy';
import PaymentYearly from './pages/payments/PaymentYearly';
import CancelSubscription from './pages/payments/CancelSubscription';
import ManageSubscription from './pages/payments/ManageSubscription';
import GotoApp from './pages/payments/GotoApp';
import FreeTrialDetails from './pages/payments/EnableFreeTiral'
import SsPricingPage from './pages/SsPricingPage'
import Player from './pages/LiveCam/player';
import { AccountLinking } from "./pages/AccountLinking";
import { EmailOTP } from "./pages/EmailOTP";




const theme = createMuiTheme(

  {
    palette: {
      primary: teal,
      secondary: amber,
    },
  });




const App: React.FC<any> = () => {
  return (
    <ThemeProvider theme={theme}>
      <div >
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <Offline>You're offline right now. Check your connection.</Offline>

        </div>
        <ToastProvider>
          <PlanProvider>
            <Router >
              <Switch>
                <Route exact path="/signin" component={SignIn} />
                {/* payments routes */}
                {/* <Route exact path="/:id/payments" component={UrlRedirect} /> */}
                <Route exact path="/ss/subscription-enabled" component={FreeTrialDetails} />
                <Route exact path="/ss/subscription-cancel" component={CancelSubscription} />
                <Route exact path="/ss/subscription-updated" component={GotoApp} />
                <Route exact path="/ss/subscription-plans" component={PlanDetails} />
                <Route exact path="/ss/subscription-manage" component={ManageSubscription} />
                <Route exact path="/ss/payment-success" component={PaymentSuccess} />
                <Route exact path="/ss/payment-monthly" component={PaymentMonthly} />
                <Route exact path="/ss/payment-yearly" component={PaymentYearly} />
                <Route exact path="/ss/payment-failure" component={PaymentFailure} />
                <Route exact path="/ss/subscription-cancelled-success" component={PremiumCancelled} />
                {/* live camera */}
                {/* <Route exact path="/ss/live" component={Player} /> */}
                <Route exact path="/sso/signin" component={SignIn} />
                <Route exact path="/" component={SignIn} />
                <Route path="/sso/signup" component={SignUp} />
                <Route path="/mobile" component={MobileNumber} />
                <AuthenticatedRoute path="/sso/review" component={Checkout} />
                <AuthenticatedRoute path="/sso/client" component={ClientDetails} />
                <AuthenticatedRoute path="/sso/register" component={ClientReg} />
                <AuthenticatedRoute path="/sso/profile" component={ProfileDetails} />
                <Route path="/sso/reset" component={Forgot} />
                <Route path="/sso/change" component={Confirmpassword} />
                <Route path="/:id/auth" component={Validate} />
                <Route path="/consent" component={Grant} />
                <AuthenticatedRoute path="/emailVerification" component={EmailOTP} />
                <AuthenticatedRoute path="/account-link" component={AccountLinking} />
                <Route path="/ss/pricing" component={SsPricingPage} />
                <Route component={PagenotFound} />
                {/* <Route component={Error} /> */}
              </Switch>
            </Router>
          </PlanProvider>
        </ToastProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
