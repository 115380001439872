import React from 'react'
import { Button, Typography, AppBar, Grid, Toolbar, Container, Card, CardActions, CardContent, Link, CssBaseline } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MaximizeIcon from '@material-ui/icons/Maximize';
// import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../../styles/plandetails.css';
import { PlanContext } from '../../Context/PlanContext';
import { useHistory } from "react-router-dom";
import FooterSection from '../../Components/PaymentFooter';
import PaymentHeader from '../../Components/PaymentHeader';
import jwt_decode from "jwt-decode";
import { SSCAM_DEEP_LINK } from '../../Components/constant'
import Coupon from '../payments/Coupon'







export const Copyright = () => {
    return (
        <div>
            <Typography variant="body2" className="foottext" align="center">

                {'Copyright © '}
                <Link color="inherit" href="http://www.mysmitch.com/" target="_blank">
                    IoT Monks Pvt. Ltd.
                    </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </div>
    );
}


export const TopHeader = () => {
    return (
        <AppBar position="static" elevation={0} style={{ backgroundColor: "#0f0d0d", color: "white", textAlign: "center", justifyContent: "center", margin: "0px", padding: "0px" }}>
            <Toolbar className="newlogo" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }} >
                <img src={require("../../assets/Group 551.svg").default} alt="smitch logo" />

            </Toolbar>
        </AppBar >
    )
}





//main component

const PlanDetails = () => {
    const value = React.useContext(PlanContext)
    let history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [data, setData] = React.useState({
        payment_token: queryParams.get("payment_token"),
        // free_trial_used:queryParams.get("free_trial_used")
    });


    const findFreetialKey = () => {
        //@ts-ignore
        let result = jwt_decode(data.payment_token);
        //@ts-ignore
        const { free_trial_used } = result
        return free_trial_used

    }

    //detaisl getting from payment Context API

    const addPlan = (name, pricing, price, description, descriptionTwo, descriptionThree) => {
        localStorage.setItem("seletedPlan", name)
        localStorage.setItem("pricing", JSON.stringify(pricing))
        localStorage.setItem("price", price)
        localStorage.setItem("description", description)
        localStorage.setItem("descriptionTwo", descriptionTwo)
        localStorage.setItem("descriptionThree", descriptionThree)
        history.push(`/ss/payment-monthly`)

    }

    const addToken = () => {
        //@ts-ignore
        localStorage.setItem("payment_token", data.payment_token)
    }















    React.useEffect(() => {
        document.title = "Smitch Plans";
        addToken()
        findFreetialKey()

    }, []);







    return (
        <React.Fragment>
            <CssBaseline />
            <PaymentHeader />
            <div style={{ color: "white", backgroundColor: "#0f0d0d" }} >
                <Container component="main" maxWidth="md" >

                    <Grid container>

                        <Grid item xs={8} sm={8} md={8} className="add" style={{ paddingTop: "30px" }}>
                            <h2 className="head"> Add <br></br>Premium<br /> Devices to<br />your home</h2>
                            {/* <p className="works"> Share with family <br />members.</p> */}
                        </Grid>


                        <Grid item xs={4} sm={4} md={4} style={{ paddingTop: "30px", paddingRight: "10px" }} >
                            <img src={require("../../assets/Group 576.svg").default} alt="4K logo" />
                        </Grid>
                    </Grid>


                </Container>
                <Container component="main" maxWidth="xs">
                    <Grid container >

                        <Grid item xs={12} sm={12} md={12} >
                            <div style={{ paddingTop: "5rem", textAlign: "center", justifyContent: "center" }}  >
                                {/* @ts-ignore */}
                                {findFreetialKey() ?
                                    <Button className="getp" style={{ color: "white", letterSpacing: "4.5px", width: "275px", borderRadius: "25px", paddingLeft: "10px" }} href="#plan_details" >
                                        GET PREMIUM
                            </Button> : <Button className="getp" style={{ color: "white", letterSpacing: "4.5px", width: "275px", borderRadius: "25px", paddingLeft: "10px" }} onClick={() => history.push(`/ss/subscription-enabled?payment_token=${data.payment_token}`)} >
                                        ENABLE FREE TRIAL
                            </Button>}

                            </div>
                            <Grid item xs={12} md={12} sm={12}>
                                <div className="benefit" style={{ textAlign: "left", justifyContent: "center" }}>
                                    <h2>Benefit with Premium</h2>

                                </div>




                                <div style={{ justifyContent: "center", textAlign: "left", alignItems: "center", marginTop: "5rem", paddingLeft: "5rem" }}>
                                    <img src={require("../../assets/mark.png").default} alt="mark logo" />

                                </div>
                                <div >
                                    {/* <p className="ben_data">Setup Geolocation places to<br></br> get alerts when your kids are in<br></br> those places</p> */}
                                    <p className="ben_data">Cloud Recordings <br></br>and IFTTT Connect</p>
                                </div>
                                <br></br>
                                <div style={{ margin: "auto", paddingTop: "20px", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/model.png").default} alt="4K logo" />

                                </div>
                                <br></br>
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", marginTop: "10px", height: "100px" }}>
                                    {/* <AliceCarousel
                        mouseTracking
                        items={items}
                        disableButtonsControls
                        paddingLeft={50}
                        paddingRight={50}
                        // autoPlay
                        // infinite
                        // autoPlayStrategy="none"
                        // autoPlayInterval={1000}
                       

                    /> */}

                                    <div className="item" data-value="3" style={{ paddingTop: "20px", margin: "5px" }}>         <img
                                        src="https://d199xmsg3owom4.cloudfront.net/images/ss_payments_cloud_storage.png"
                                        alt="player cloud logo" />
                                    </div>
                                    <div className="item" data-value="1" style={{ paddingTop: "32px", margin: "5px" }} >
                                        <img src="https://d199xmsg3owom4.cloudfront.net/images/payments_ifttt.png"
                                            alt="iftt logo" />
                                    </div>
                                    {/* <div className="item" data-value="2" style={{ paddingTop: "21px", margin: "5px" }}>         <img
                        src="https://d199xmsg3owom4.cloudfront.net/images/ss_payments_web_stream.png"
                        alt="web stream logo" />
                    </div> */}

                                </div>
                            </Grid>
                        </Grid >
                    </Grid>
                </Container>

                {/* @ts-ignore */}

                <Container component="main" maxWidth="xs">
                    <Grid container>
                        <Grid item xs={12} md={12} sm={12} >
                            <div className="up">
                                <h2 className="upgrade" style={{ marginLeft: "5rem" }}>Upgrade<br />
                        your device</h2>
                                <div style={{ justifyContent: "center", textAlign: "left", alignItems: "center", marginLeft: "5rem" }}>
                                    <img src={require("../../assets/mark.png").default} alt="4K logo" />
                                </div>
                                <p className="up_data">Select a plan to see all offers, <br></br> durations and payment options</p>
                            </div>

                        </Grid>
                    </Grid>
                </Container>

                {/* @ts-ignore */}

                <Container maxWidth="md" component="main" id="plan_details">
                    <Grid container spacing={5} alignItems="flex-end" >
                        {value[0].map((tier, i) => (

                            <Grid xs={12} sm={6} md={4} item key={i} style={{ borderRadius: "5px" }} >
                                {(() => {
                                    if (i <= 2) {
                                        return (
                                            <Card className="maincard" >
                                                <p className="planname">{tier.title}</p>
                                                <div style={{ textAlign: "center" }}>
                                                    <img src={require("../../assets/mark.png").default} alt="4K logo" />
                                                </div>
                                                <div style={{ paddingTop: "40px" }}>
                                                    {(() => {
                                                        if (i == 1) {
                                                            return (
                                                                <aside style={{ color: "white" }}>
                                                                    <p className="times" >
                                                                        <span style={{ color: "#0883c0" }}>|</span> Recommended
                                                        </p>
                                                                </aside>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                                <div style={{ alignItems: "center" }} >
                                                    <p className="pricing" >
                                                        {/* @ts-ignore */}
                                                        <span><MaximizeIcon style={{ height: "30px", width: "30px", paddingTop: "20px" }} /> </span> {tier.card} <MaximizeIcon style={{ height: "30px", width: "30px", paddingTop: "20px" }} />
                                                    </p>
                                                </div>

                                                <CardContent>
                                                    <div>
                                                        {/* @ts-ignore */}
                                                        {tier.description.map((line, i) => (
                                                            <div key={i} >
                                                                <p className="plan__description"> <AddIcon color="primary" style={{ height: "10px", width: "10px" }} /> {line}</p>                                                </div>
                                                        ))}
                                                    </div>

                                                    {/* @ts-ignore */}
                                                    <div><p className="offer" >{tier.monthly_offer}</p></div>
                                                </CardContent>
                                                <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {/* @ts-ignore */}

                                                </CardActions>
                                                {/* @ts-ignore */}
                                                {findFreetialKey() ?
                                                    <Button className="view__plan" style={{ color: "white", letterSpacing: "4.5px", width: "280px", borderRadius: "25px" }} onClick={() => addPlan(tier.title, tier.pricing, tier.pricing.monthly.price, tier.description[0], tier.description[1], tier.description[2])}>{tier.buttonText}
                                                    </Button> : null}
                                            </Card>
                                        )
                                    }
                                })()}
                            </Grid>
                        ))}
                    </Grid>
                    {/* <Coupon service="ss" /> */}
                </Container>


                <Container component="main" maxWidth="xs">
                    <Grid container>

                        <Grid item xs={12} sm={12} md={12} >
                            <div style={{ color: "white", paddingTop: "20px", margin: "auto", justifyContent: "center" }}>
                                <h1 className="anything">Anything else?</h1>
                                <div style={{ textAlign: "left", justifyContent: "left", paddingLeft: "58px" }}>
                                    <img src={require("../../assets/mark.png").default} alt="4K logo" />
                                </div>
                                <p className="anything_text">Feel free to drop us a message <br></br>and our support team will get<br></br> back to you very soon.</p>
                            </div>
                            <div style={{ color: "white", paddingTop: "30px", paddingBottom: "20px", margin: "auto" }}>
                                <Button className="chat_suppport" style={{ color: "white", marginLeft: "3rem", paddingTop: "20px", letterSpacing: "4.5px", width: "275px", borderRadius: "25px" }} href="https://chatsupport.mysmitch.com" target="_blank">CHAT SUPPORT
                        </Button>

                            </div>
                        </Grid>
                    </Grid>


                </Container>
                <FooterSection />
            </div>
        </React.Fragment>
    )
}

export default PlanDetails
