import React from 'react';
import { Button, makeStyles, Typography, Grid, CssBaseline } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from "react-router"
import PaymentHeader from '../../Components/PaymentHeader'



import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/CancelSubscription.css';
import axios from "axios";
import { ToastContext } from "../../Components/ToastProvider";
import FooterSection from '../../Components/PaymentFooter';
import { API_URL } from '../../Components/constant';
import httpClient from '../../Components/Service';

import moment from "moment";
import Chip from '@material-ui/core/Chip';
import * as Sentry from "@sentry/react";
import StarsIcon from '@material-ui/icons/Stars';










const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }, root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

//@ts-ignore
const DialogTitle = withStyles(styles)((props) => {
    //@ts-ignore
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);



const CancelSubscription = () => {

    let history = useHistory()
    const classes = useStyles();
    const { showToast } = React.useContext(ToastContext);
    const queryParams = new URLSearchParams(window.location.search);
    const [subscription, setSubcription] = React.useState({})
    const [data, setData] = React.useState({
        payment_token: queryParams.get("payment_token"),
    });

    const [user, setUser] = React.useState({
        email: "",
        password: "",
    });
    const [open, setOpen] = React.useState(false);
    const [faqArray, setFaqArray] = React.useState([])


    const getToken = () => {
        //@ts-ignore
        return localStorage.getItem("payment_token")
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }



    const fetchSubscriptionDetails = async () => {
        try {
            const result = await axios.post(API_URL + "subscription/list",
                {
                    "payment_token": getToken(),
                    "service": "ss"
                })
            console.log("subscription", result.data.data[0])
            setSubcription(result.data.data[0])
            if (result && result.data != null) {
                // showToast(result.data.message, "success")
            }
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            console.log(error)
            Sentry.captureException(error);

        }
    }




    const addToken = () => {
        //@ts-ignore
        localStorage.setItem("payment_token", data.payment_token)

    }

    React.useEffect(() => {
        document.title = "SMITCH | Cancellation";
        window.scrollTo(0, 0)
        fetchSubscriptionDetails()
        addToken()
        fetchFAQ()
    }, []);


    //cancel subscription
    const cancelSubscription = async () => {
        try {
            const result = await axios.post(API_URL + "subscription/cancel",
                {
                    "payment_token": data.payment_token,
                    "service": "ss",
                    "reason": "cancel_subscription",
                    //@ts-ignore
                    "subscription_id": subscription.id
                })
            if (result && result.data != null) {
                showToast(result.data.message, "success")
                history.push("/subscription-cancelled-success")
            }
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            console.log(error)
            Sentry.captureException(error);

        }
    }


    //FAQ

    const fetchFAQ = async () => {
        try {
            const result = await httpClient(`pay/ss/faq?payment_token=${getToken()}`, "GET");
            if (result.data && result.data != undefined) {
                setFaqArray(result.data.config[0].faqs)
            }


        }
        catch (error) {
            console.log(error)
            showToast(error.response.data.message, "error")

            Sentry.captureException(error);


        }
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <PaymentHeader />

            <div className="Main" style={{ paddingTop: "65px" }}>
                <div className="change__method" style={{ backgroundColor: "black" }}>
                    {/* @ts-ignore */}

                    <p className="plan_heading" style={{ textAlign: "center", }}>Smitch Premium </p>
                    <br></br>
                    <div style={{ textAlign: "center" }}>
                        {/* @ts-ignore */}
                        {subscription.type === "BRONZE" &&

                            <Chip
                                size="small"
                                icon={<StarsIcon />}
                                //@ts-ignore
                                label={subscription.type}
                                style={{ backgroundColor: "#CD7F32", color: "white" }}
                            />

                        }
                        {/* @ts-ignore */}
                        {subscription.type === "SILVER" &&

                            <Chip
                                size="small"
                                icon={<StarsIcon />}
                                //@ts-ignore
                                label={subscription.type}
                                style={{ backgroundColor: "#C0C0C0", color: "white" }}
                            />

                        }
                        {/* @ts-ignore */}
                        {subscription.type === "GOLD" &&

                            <Chip
                                size="small"
                                icon={<StarsIcon />}
                                //@ts-ignore
                                label={subscription.type}
                                style={{ backgroundColor: "#FFD700", color: "black" }}
                            />

                        }
                        {/* @ts-ignore */}
                        {subscription.type === "GOLD_TRIAL" &&
                            <Chip
                                size="small"
                                icon={<StarsIcon />}
                                //@ts-ignore
                                label={subscription.type}
                                style={{ backgroundColor: "#FFD700", color: "black" }}
                            />

                        }
                        {/* <Chip
                        //@ts-ignore
                    label={subscription.type}
                        color="primary"></Chip> */}

                    </div>

                    {/* <div >
                        <br></br>
                        <h2 className="method">Payment method</h2>
                        <div style={{ justifyContent: "center" }}>
                            <img style={{ paddingLeft: "112px" }} src={require("../../assets/mark.png").default} alt="blue mark" />
                        </div>
                        <br></br>
                    </div>
                    <div  className="method__button" style={{marginLeft:"66px" , height:"68px" ,textAlign:"center" , width:"275px"}}>
                        <Button  style={{ color: "white",paddingLeft: "36px",letterSpacing:"4.5px" }}>
                            change payment method
                         </Button>
                    </div> */}

                    <div className="date">
                        {/* @ts-ignore */}
                        <p>Subscription will auto-renew on:<br></br> {moment(subscription.current_end).format('LLL')}</p>
                    </div>
                    <br></br>

                </div>
                <div style={{ backgroundColor: "black", textAlign: "center", }} className="cancel__button">
                    <Button style={{ color: "white", paddingLeft: "36px", letterSpacing: "4.5px" }} onClick={handleClickOpen}>cancel subscription</Button>
                </div>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                {/* @ts-ignore */}
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ backgroundColor: "black" }}>
                </DialogTitle>
                <div style={{ paddingLeft: "36px", backgroundColor: "black", height: "333px" }}>
                    <div style={{ color: "white" }}>
                        <h2 className="can__confirm" style={{ marginTop: "10px" }}>Cancel your Premium <br></br>subscription?</h2>

                        <p className="can__text" style={{ marginTop: "20px" }}>Upon confirmation, you will enjoy premium benefits only till this billing cycle.<br></br>
                            <br></br>
                            Your subscription won’t be renewed. </p>
                    </div>
                    <br></br>
                    <div  >
                        <Button className="btn__keep" style={{ color: "white", letterSpacing: "4.5px", borderRadius: "25px" }} onClick={handleClose}>
                            KEEP PREMIUM
                        </Button>
                    </div>
                    <br></br>

                    <div className="btn__cancel">
                        <Button className="btn__cancel" style={{ color: "white", letterSpacing: "4.5px" }} onClick={cancelSubscription}>
                            CANCEL SUBSCRIPTION
                        </Button>
                        <br></br>
                    </div>

                </div>
            </Dialog>


            {/* FAQ sections */}
            <div style={{ backgroundColor: "black", paddingLeft: "49px", paddingTop: "10px" }}>
                <h1 className="faq_head">FAQs</h1>
                <img src={require("../../assets/mark.png").default} alt="blue mark" />
            </div>
            <div className="faq" style={{ backgroundColor: "black", color: "white", paddingTop: "10px" }} >
                <Grid>
                    {faqArray.map((faq, i) =>
                        <Accordion className="accordion" key={i}  >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: "#fcfcfc" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {/* @ts-ignore */}
                                <Typography className={classes.heading}>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {/* @ts-ignore */}
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}

                </Grid >
                <br></br>
                <br></br>
            </div>

            <FooterSection />

        </React.Fragment>
    )
}

export default CancelSubscription
