import React, { useState } from "react";
import httpClient from '../Components/Service';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, useRouteMatch, } from "react-router-dom";

const Validate: React.FC = (props) => {

    let match = useRouteMatch();
    //@ts-ignore
    console.log("match", match.params.id)


    const queryParams = new URLSearchParams(window.location.search);
    let history = useHistory();

    const [state, setState] = useState([])

    const [data, setData] = useState({
        client_id: queryParams.get("client_id"),
        redirect_uri: queryParams.get("redirect_uri"),
        response_type: queryParams.get("response_type"),
    });




    const [user, setUser] = useState({
        client_id:encodeURIComponent(queryParams.get("client_id")  as string),
        redirect_uri: queryParams.get("redirect_uri"),
        state: queryParams.get("state"),
        //@ts-ignore
        service: match.params.id


    });


    const fetchClient = async () => {
        try {
            const result = await httpClient("oauth/client/verify", "POST", { "client_id": user.client_id, });
            setState(result)
            if (result && result.status === "success") {
                return history.push(`/signin?redirect_uri=${user.redirect_uri}&client_id=${user.client_id}&state=${user.state}&service=${user.service}`)
            }
            return history.goBack()
        } catch (error) {
            console.log(error)
        }
    }



    React.useEffect(() => {
        document.title = "Smitch | Allow "
        fetchClient()

    }, []);


    return (
        <React.Fragment>

        </React.Fragment>
    );
};

export default Validate;
