import React from 'react';
import { Button, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import '../../styles/fail.css';
import FooterSection from '../../Components/PaymentFooter'
import axios from "axios";
import { ToastContext } from "../../Components/ToastProvider";
import { API_URL } from '../../Components/constant';
import * as Sentry from "@sentry/react";
import {SSO_BASE} from '../../Components/constant';
import PaymentHeader from '../../Components/PaymentHeader'





const PremiumCancelled = () => {


    const [subscription, setSubcription] = React.useState({})

    const { showToast } = React.useContext(ToastContext);
    const [user, setUser] = React.useState({
        email: "",
        comment: "",
        feed:""
    });

    const handleChange = (event: any) => {
        setUser({ ...user, [event.target.name]: event.target.value });

    }


    const getToken=()=>{
        //@ts-ignore
       return localStorage.getItem("payment_token")
    }
    React.useEffect(() => {
        document.title = "Subscription cancel | SMITCH";
        fetchSubscriptionDetails();
        getToken()
    }, []);
  
    
    const fetchSubscriptionDetails = async () => {
        try {
            const result = await axios.post(API_URL+"subscription/list",
                {
                    "payment_token": getToken(),
                    "service": "ss"
                })
                console.log("subscription" , result.data.data[0])
            setSubcription(result.data.data[0])
            // if (result && result.data != null) {
            //     showToast(result.data.message, "success")
            // }
        }
        catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    }

    

    //sending feedback
    const handleFeedback = async () => {
        try {
            const result = await axios.post(SSO_BASE+"pay/submitFeedback", {
                //@ts-ignore
                "user_id": subscription.user_id,
                "identifier": "12",
                "type": "cancellation",
                "service": "ss"
            });
            if (result && result != null) {
                //@ts-ignore
                showToast("Your feedback  sent successfully!", "success");
            }
        } catch (error) {
            showToast(error.response.data.message, "error")
            Sentry.captureException(error);

        }
    }






    return (
        <React.Fragment>
            <PaymentHeader/>
       
            <div style={{ backgroundColor:"black", color: "white", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <div>
                    <div className="firstdiv">
                        <br></br>
                        <h2 className="cancel_text">Premium subscription <br></br>cancelled</h2>
                        <br></br>
                        <img src={require("../../assets/lock.png").default} alt="smitch logo" />
                    </div>
                    <div className="failure">
                        <p className="fail__text">
                            We’re sorry to see you go
                        </p>
                        <br></br>
                        <h1 className="we_would">We’d love <br></br>your feedback</h1>
                        <div style={{textAlign:"left" , justifyContent:"left" , paddingLeft:"64px"}}>
                            <img src={require("../../assets/mark.png").default} alt="4K logo" />
                        </div>
                        <p className="we_text">Please let us know what went<br></br> wrong, so we can work on it to<br></br> provide a better service next<br></br> time.</p>
                    
                    </div>
                    <br></br>
                    <div style={{ backgroundColor: "black", paddingTop:"40px" }}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="FEED" name="feed" value={user.feed} onChange={handleChange}>
                                <FormControlLabel name="feed"  value="It didn’t work as expected ." onChange={handleChange} control={<Radio  style={{color:"#1f92e3"}} />} label=" It didn’t work as expected ." />
                                <FormControlLabel  name="feed" value="I faced problems while using it ." onChange={handleChange} control={<Radio  style={{color:"#1f92e3"}}/>} label="I faced problems while using it ." />
                                <FormControlLabel name="feed" value="I don’t need premium anymore ." onChange={handleChange} control={<Radio  style={{color:"#1f92e3"}}/>} label="I don’t need premium anymore ." />
                                <FormControlLabel name="feed" value="The features were not useful ." onChange={handleChange} control={<Radio style={{color:"#1f92e3"}} />} label="The features were not useful ." />
                            </RadioGroup>
                        </FormControl>

                    </div>
                    <div style={{ backgroundColor: "black" }}>
                        <form noValidate autoComplete="off">
                            <br></br>
                            <TextareaAutosize name="comment" onChange={handleChange} value={user.comment} style={{ height: "76px", width: "266px" }} maxLength={150} minLength={25} rowsMin={6} placeholder="  Any additional comment …." />
                        </form>
                    </div>
                    <br></br>
                    <Grid  >
                        <Button className="chat_suppport" style={{ color: "white" ,letterSpacing:"4.5px" , width:"275px" , borderRadius:"25px" }} onClick={handleFeedback}>SUBMIT
                        </Button>
                    </Grid>
                </div>
                <div style={{ backgroundColor: "black", color: "white" , paddingTop:"20px" }}>
                    <h1 className="anything">Anything else?</h1>
                    <div style={{textAlign:"left" , justifyContent:"left" , paddingLeft:"74px"}}>
                            <img src={require("../../assets/mark.png").default} alt="4K logo" />
                        </div>
                    <p className="anything_text">Feel free to drop us a message <br></br>and our support team will get<br></br> back to you very soon.</p>
                </div>
                <div style={{ backgroundColor: "black", color: "white" , paddingTop:"30px" }}>
                    <Button className="chat_suppport" style={{ color: "white" , paddingTop:"20px" ,letterSpacing:"4.5px" , width:"275px" , borderRadius:"25px"}} href="https://chatsupport.mysmitch.com" target="_blank">CHAT SUPPORT
                        </Button>
                </div>
                <br></br>
                <br></br>
            <FooterSection/>
            </div>

        </React.Fragment>



    )
}

export default PremiumCancelled
