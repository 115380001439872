import { Box, Button, Container, FormControl, makeStyles, OutlinedInput, TextField, Typography, Grid, Paper, Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from 'query-string';
import OTPInput, { ResendOTP } from "otp-input-react";
import { DEFAULT_CLIENT_ID, SSO_BASE } from "../Components/constant";
import httpClient from "../Components/Service";
import { ToastContext } from "../Components/ToastProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    inputLabel: {
        color: "#000"
    },
    subtitle: {
        marginBottom: "20px"
    },
    button: {
        margin: theme.spacing(1),
    },
    container: {
        textAlign: "center",
        justifyContent: "center"
    },
    wrapper: {
        padding: "20px"
    },
    otpWrapper: {
        margin: "20px 0px"
    },
    resendBtn: {
        color: "#009688",
        backgroundColor: "#FFF",
        border: "0px",
        boxShadow: "none",
        transition: "none",
        marginLeft: "5px"
        /* -webkit-appearance: none; */
    }
}));

export const EmailOTP = () => {
    const classes = useStyles();
    const location = useLocation();
    let history = useHistory()
    const [email, setEmail] = useState("");
    const [loginType, setLoginType] = useState("email")
    const [errorTxt, setErrorText] = useState("");
    const [isEditable, setEditable] = useState(true);
    const [otpSent, setOtpSent] = useState(false);
    const [OTP, setOTP] = useState("");
    const [queryParams, setQParams] = useState("");
    const [resendCount, setResendCount] = useState(0);
    const [otpExpiry, setOtpExpiry] = useState("")
    const { showToast } = React.useContext(ToastContext);


    // const queryParams = new URLSearchParams(window.location.search);
    const [res, setRes] = React.useState({
        client_id: "",
        //@ts-ignore
        redirect_uri: "",
        response_type: "",
        state: "",
        service: ""
    });

    const handleChange = (event) => {
        let emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        if (isEditable) {
            setEmail(event.target.value);
            if (!emailRegex.test(event.target.value)) {
                setErrorText("Please enter a valid email");
            }
            else {
                setErrorText("");
            }
        }
    }

    useEffect(() => {
        let data = location.state as any;
        if (data.reg_email && data.reg_email != "") {
            setEmail(data.reg_email);
            setEditable(false)
        }
        if (data.login_type && data.login_type != "") {
            setLoginType(data.login_type)
        }
        if (data.search && data.search != "") {
            let qParams = queryString.parse(data.search);
            setQParams(qParams)
            setRes({
                //@ts-ignore
                client_id: encodeURIComponent(qParams["client_id"] || DEFAULT_CLIENT_ID),
                //@ts-ignore
                redirect_uri: encodeURIComponent(qParams["redirect_uri"]),
                response_type: qParams["response_type"],
                state: qParams["state"],
                service: qParams["service"] || "sso"

            })
        }
    }, [])

    const sendOtpClick = async () => {
        try {
            const result = await httpClient("oauth/sso/accountlink/smitch/send/otp", "POST", { "email": email, "login_type": loginType, "client_id": res.client_id });
            if (result && result.status == "success") {
                //@ts-ignore
                setOtpSent(true);
                let date_str = ""
                let date = new Date();
                date.setHours(date.getHours(), date.getMinutes() + 5, 0, 0);
                console.log("date", date)
                date_str = date.getHours().toString() + ":" + date.getMinutes().toString();
                setOtpExpiry(date_str);
            }
        }
        catch (error) {
            console.log("error", error)
            showToast("Something went wrong", "error")
        }
    }

    const otpSubmit = async () => {
        try {
            const result = await httpClient("oauth/sso/accountlink/smitch/validate/otp", "POST", {
                "email": email,
                "otp": OTP,
                "client_id": res.client_id,
            });
            if (result && result != null && result.status == "success") {
                return history.push(`/account-link?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
            }
            else {
                showToast(result.message, "error");
            }
        } catch (error) {
            console.log(error);
            showToast("Something went wrong", "error");
        }
    }

    const resendClick = () => {
        setResendCount(resendCount + 1);
        if (resendCount < 3)
            sendOtpClick()
        else
            showToast("You have reached the maximum times to resend OTP. Try Again Later", "error");
    }

    const renderResendText = () => {
        return (
            <div>Resend</div>
        )
    }

    const renderTime = () => React.Fragment

    const ResendHandle = (e) => {
        sendOtpClick()
    }

    return (
        <div style={{ backgroundColor: "#a9dfdb" }}>
            <Container maxWidth="xs" className={classes.container}>
                <Grid container alignItems='center' justify='center' style={{ minHeight: "100vh" }}>
                    <Paper elevation={3} className={classes.wrapper}>
                        {
                            otpSent === false &&
                            <>
                                <Box my={3} textAlign="center">
                                    <Box my={2} textAlign="center">
                                        <img
                                            src={require("../SMITCH.png").default}
                                            alt="smitch logo"
                                            style={{ height: "60px", width: "60px" }}
                                        />
                                    </Box>
                                    <Typography variant="h6">Verify Your Email Address</Typography>
                                </Box>
                                <Box my={3} display="flex" justifyContent="center" alignItems="center" >
                                    <form>
                                        <Typography variant="body1" className={classes.subtitle}>We will send an OTP to your registered email.</Typography>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                id="outlined-full-width"
                                                label="Email Address"
                                                style={{ margin: 8 }}
                                                placeholder="Email Address"
                                                fullWidth
                                                error={errorTxt != ""}
                                                helperText={errorTxt}
                                                disabled={!isEditable}
                                                value={email}
                                                onChange={(event) => handleChange(event)}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                        <Box my={2} textAlign="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={() => sendOtpClick()}
                                                className={classes.button}
                                                disabled={email == "" || errorTxt != ""}
                                            >
                                                SEND OTP
                                        </Button>
                                        </Box>
                                    </form>
                                </Box>
                            </>
                        }
                        {
                            otpSent === true &&
                            <>
                                <Box my={3} textAlign="center">
                                    <Box my={2} textAlign="center">
                                        <img
                                            src={require("../SMITCH.png").default}
                                            alt="smitch logo"
                                            style={{ height: "60px", width: "60px" }}
                                        />
                                    </Box>
                                    <Typography variant="h6">Please Enter the One-Time Password sent to your mail</Typography>
                                </Box>
                                <Grid className={classes.otpWrapper}>
                                    <OTPInput style={{ display: "inline" }}
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        maxTime={2}
                                        inputStyles={{ margin: "5px 3px" }}
                                        className="sendOtp"
                                    />
                                </Grid>
                                <Box my={2} textAlign="center">
                                    <Typography variant="body2">Your OTP is valid till {otpExpiry}</Typography>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    disabled={OTP.length < 6}
                                    onClick={() => otpSubmit()}
                                >
                                    Verify OTP
                                </Button>
                                <Box my={2} textAlign="center">
                                    <Typography variant="caption" display="inline" >
                                        Didnt receive OTP?
                                    </Typography>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        className={classes.resendBtn}
                                        onClick={() => {
                                            resendClick()
                                        }}
                                    >
                                        Resend OTP
                                    </Link>
                                </Box>
                                {/* <ResendOTP style={{ diplay: "block", justifyContent: "center" }} renderButton={renderResendText} renderTime={renderTime} onResendClick={(e) => ResendHandle(e)} /> */}
                            </>
                        }
                    </Paper>
                </Grid>
            </Container>
        </div>
    )
}