import React, { useContext, useState } from 'react';
import Header from '../Components/header';
import { Box, Button, Container, TextField, Typography, makeStyles, IconButton, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, ClickAwayListener, Tooltip, } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LinkIcon from '@material-ui/icons/Link';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik';
import { ToastContext } from '../Components/ToastProvider';
import { API_URL, DEFAULT_CLIENT_ID, SSO_BASE } from '../Components/constant';
import axios from 'axios';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    icon: {
        cursor: "pointer"
    },
    infoIconWrapper: {
        position: "relative"
    },
    tooltip: {
        // backgroundColor: "#FFF", 
        // color: "#000", 
        fontSize: theme.typography.pxToRem(15),
        marginTop: "0px"
    },
    shLogoIcon: {
        width: "40px",
        height: "40px",
        margin: "0px 10px"
    },
    textFieldWrapper: {
        position: "relative"
    },
    bar: {
        position: "absolute",
        display: "block",
        width: "0px",
        height: "4px",
        border: "1px",
        top: "72px",
        // backgroundColor:" #9b59b6",
        transition: "all .4s",
        borderRadius: "5px"
    },
    none: {
        backgroundColor: "#FFF",
        width: "0px !important",
    },
    weak: {
        width: "33.3% !important",
        backgroundColor: "#e74c3c"
    },
    medium: {
        width: "66.6% !important",
        backgroundColor: "#e67e22"
    },
    strong: {
        width: "100% !important",
        backgroundColor: "#2ecc71"
    }
}));

export const AccountLinking = () => {
    const classes = useStyles();
    const [radioVal, setRadioVal] = useState('existing-pwd');
    const [infoPopup, showInfoPopup] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confPasswordShown, setconfPasswordShown] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState("none");
    const { showToast } = useContext(ToastContext);
    const queryParams = new URLSearchParams(window.location.search);
    let history = useHistory()
    const [res, setRes] = React.useState({
        //@ts-ignore
        client_id: encodeURIComponent(queryParams.get("client_id") || DEFAULT_CLIENT_ID),
        //@ts-ignore
        redirect_uri: encodeURIComponent(queryParams.get("redirect_uri")),
        response_type: queryParams.get("response_type"),
        state: queryParams.get("state"),
        service: queryParams.get("service") || "sso"

    });
    let service = "";
    if (localStorage.getItem("service") === "ss")
        service = "(Smitch Secure)"
    else if (localStorage.getItem("service") === "sh")
        service = "(Smitch Home)"
    const infoIconText: string = "Smitch Account Linking would link all the services that you have been using under one account. After successful account linking, you should be able to sign-in with your credentials for all third-party integration services like IFTTT, Google Home, Alexa etc...";

    const togglePasswordVisiblity = (val: string) => {
        if (val === "confirmPwd")
            setconfPasswordShown(confPasswordShown ? false : true);
        else
            setPasswordShown(passwordShown ? false : true);
    };

    let icon: any;
    if (passwordShown == true) {
        icon = <Visibility className={classes.icon} onClick={() => togglePasswordVisiblity("newPwd")} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOff className={classes.icon} onClick={() => togglePasswordVisiblity("newPwd")} />;
    }

    let confIcon: any;
    if (confPasswordShown == true) {
        confIcon = <Visibility className={classes.icon} onClick={() => togglePasswordVisiblity("confirmPwd")} />;
    } else if (confPasswordShown == false) {
        confIcon = <VisibilityOff className={classes.icon} onClick={() => togglePasswordVisiblity("confirmPwd")} />;
    }


    const onRadioBtnClick = (event: any) => {
        setRadioVal(event.target.value)
    }

    const onClickAway = () => {
        showInfoPopup(false)
    }

    const onInfoIconClick = () => {
        showInfoPopup((prev) => !prev);
    }

    const validate = (values: any) => {
        let errors = {};
        if (radioVal === "new-pwd") {
            if (values.password == "") {
                Object.assign(errors, { ["password"]: "Password cannot be empty" });
                setPasswordStrength("none");
            }
            else {
                let pass_val = values.password;
                let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
                var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");

                var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");

                var okRegex = new RegExp("(?=.{6,}).*", "g");

                if (okRegex.test(pass_val) === false) {
                    //weak
                    setPasswordStrength("weak");
                } else if (strongRegex.test(pass_val)) {
                    //strong
                    setPasswordStrength("strong");
                } else if (mediumRegex.test(pass_val)) {
                    setPasswordStrength("medium")
                } else {
                    setPasswordStrength("medium")
                }
                if (!regex.test(values.password))
                    Object.assign(errors, { ["password"]: "Password must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character" });
            }
            if (values.confPassword == "") {
                Object.assign(errors, { ["confPassword"]: "Confirm Password cannot be empty" });
            }
        }
        console.log("error", errors)
        return errors;
    };

    return (
        <>
            {/* <Header/> */}
            <Container maxWidth="sm">
                <Box my={3} display="flex" justifyContent="center" alignItems="center">
                    <img
                        src="static/images/SMITCH.png"
                        alt="SHLogo"
                        className={classes.shLogoIcon}
                    />
                    <FiberManualRecordIcon style={{ fontSize: 15, color: "57AC76" }} />
                    <FiberManualRecordIcon style={{ fontSize: 15, color: "57AC76" }} />
                    <LinkIcon style={{ color: "757575" }} />
                    <FiberManualRecordIcon style={{ fontSize: 15, color: "757575" }} />
                    <FiberManualRecordIcon style={{ fontSize: 15, color: "757575" }} />
                    <img
                        src="https://d199xmsg3owom4.cloudfront.net/images/ss_logo.png"
                        alt="ss logo"
                        className={classes.shLogoIcon}
                    />
                </Box>
                <Box my={3} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="h6" display="inline">Smitch Account Linking</Typography>
                    <ClickAwayListener onClickAway={() => onClickAway()}>
                        <div>
                            <IconButton color='primary' onClick={() => onInfoIconClick()}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </ClickAwayListener>
                </Box>
                {infoPopup ? (
                    <div>
                        {infoIconText}
                    </div>
                ) : null}
                <Formik
                    initialValues={{
                        password: "",
                        confPassword: ""
                    }}
                    validate={(initialValues) => validate(initialValues)}
                    onSubmit={async (values: any) => {
                        if (radioVal === "new-pwd") {
                            if (values.password != values.confPassword)
                                showToast("Passwords did not match", "error")
                        }
                        let data: any = {};
                        if (radioVal === "new-pwd") {
                            data = {
                                "type": "new",
                                "password": values.password,
                                "client_id": res.client_id,
                                "origin_service": localStorage.getItem("service")
                            }
                        }
                        else {
                            data = {
                                "type": "existing",
                                "client_id": res.client_id,
                                "origin_service": localStorage.getItem("service")
                            }
                        }


                        try {
                            const result = await axios.post(SSO_BASE + "oauth/sso/accountlink/smitch",
                                data,
                                {
                                    headers: {
                                        "access_token": localStorage.getItem("access_token")
                                    }
                                })
                            showToast(result.data.message, "success");
                            history.push(`/consent?redirect_uri=${res.redirect_uri}&client_id=${res.client_id}&state=${res.state}&service=${res.service}`)
                        }
                        catch (error) {
                            // Sentry.captureException(error);
                            showToast(error.response.data.message, "error")
                        }
                    }}>

                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box my={3}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="password" name="password" value={radioVal} onChange={(event) => onRadioBtnClick(event)}>
                                        <FormControlLabel value="existing-pwd" control={<Radio color="primary" />} label={`Use existing password ${service} for all Smitch Apps`} />
                                        <FormControlLabel value="new-pwd" control={<Radio color="primary" />} label="Type in a new password" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {
                                radioVal === "new-pwd" &&
                                <>
                                    <div className={classes.textFieldWrapper}>
                                        <TextField
                                            InputProps={{
                                                endAdornment: icon
                                            }}
                                            error={Boolean(errors.password)}
                                            fullWidth
                                            helperText={errors.password}
                                            label="Enter a New Password"
                                            margin="normal"
                                            name="password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={passwordShown ? "text" : "password"}
                                            value={values.password}
                                            variant="outlined"
                                        />
                                        <div className={`${classes.bar} ${classes[passwordStrength]}`} ></div>
                                    </div>
                                    <TextField
                                        error={Boolean(errors.confPassword)}
                                        fullWidth
                                        helperText={errors.confPassword}
                                        label="Re-type New Password"
                                        margin="normal"
                                        name="confPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type={confPasswordShown ? "text" : "password"}
                                        value={values.confPassword}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: confIcon
                                        }}
                                    />
                                </>
                            }
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Submit
                            </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Container>
        </>
    )
}